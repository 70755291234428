import React, {useState} from 'react';
import PageWrapper from '../components/Layout/PageWrapper';
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Appointment from "../components/Appointment";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Button from "../components/Button";

export default function ListRDV() {
  const navigate = useNavigate();
  const [currentAppointmentsData, setCurrentAppointementsData] = useState<any>(
        []
  );
  return (
    <PageWrapper>
      <Grid container spacing={0}  justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
              {currentAppointmentsData && currentAppointmentsData.length > 0 && (
                  <div className="appointments">
                      <h2>Liste des rendez-vous</h2>
                      {currentAppointmentsData.map(
                          (appointment: any, index: number) => (
                              <Appointment key={index} appointment={appointment} />
                          )
                      )}
                  </div>
              )}
              <Pagination
                  apiData="/appointments"
                  getCurrentItems={(current) => {
                      if (!areObjectsEqual(current, currentAppointmentsData)) {
                          setCurrentAppointementsData(current);
                      }
                  }}
              />
              <Button text="retour" to={() => navigate("/")} />
          </Grid>
      </Grid>
    </PageWrapper>
  );
}
