import Cookies from "js-cookie";

const setHeaderInCondition = (
  headers: any,
  condition: any,
  headerName: string,
  value: string
) => {
  if (condition) {
    headers[headerName] = value;
  }
  return headers;
};

const handleUnAutorized = () => {
  Cookies.remove("token");
  if (window.location.pathname !== "/") {
    alert(
      "Non autorisé, assurez-vous d'avoir les bons accès ou de vous connecter à nouveau."
    );
    window.location.href = "/";
  }
};

export { setHeaderInCondition, handleUnAutorized };
