import React, { FC } from "react";
import Button from "./Button";

interface PopupProps {
  onClose?: () => void;
  children?: React.ReactElement;
}

const Popup: FC<PopupProps> = ({ onClose, children }) => {
  return (
    <>
      <div className="modal modal-popup">
        <div className="modal-wrapper">
          <div className="modal-content">
            <div className="modal-body">{children}</div>
            {onClose && (
              <div className="modal-footer">
                <Button text="Retour" size="sm" onPress={onClose} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
