import { useState } from "react";
import { useDispatch } from "react-redux";
import { setModel } from "../state/slices/appSlice";

function useNomenclature(itemData: any) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: "",
    category: "",
    subCategories: [{ name: "" }],
  });

  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index] = {
      ...updatedSubCategories[index],
      [name]: value,
    };
    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const handleAddSubCategory = () => {
    if (
      formData.subCategories[
        formData.subCategories.length - 1
      ]?.name?.trim() === ""
    ) {
      dispatch(
        setModel({
          type: "error",
          content: "veuillez remplir le champ avant d'en ajouter un nouveau",
          onClose: () => dispatch(setModel(null)),
        })
      );
      return;
    }

    // Add a new subcategory
    setFormData({
      ...formData,
      subCategories: [...formData.subCategories, { name: "" }],
    });
  };

  return { formData, setFormData, handleInputChange, handleAddSubCategory };
}

export default useNomenclature;
