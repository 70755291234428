import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import Logo from "../assets/images/cfc-logo.png";
import CFCApi from "../apis";
import decode from "jwt-decode";
import { Grid } from "@mui/material";

export default function Login() {
  const dispatch = useDispatch();

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      dispatch(startLoading());
      const response = await CFCApi.post("/login", {
        email: values.username,
        password: values.password,
      });

      

      if (response?.token) {
        const decoded: any = decode(response.token);
        const expirationDate = new Date(decoded.exp * 1000);
        document.cookie = `token=${
          response.token
        }; expires=${expirationDate.toUTCString()}; path=/`;
        window.location.reload();
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while logging in",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      // Login failed due to invalid credentials
     
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const formFields: Field[] = [
    {
      name: "username",
      type: "text",
      label: "Identifiant",
      required: true,
    },
    {
      name: "password",
      type: "password",
      label: "Mot de passe",
      required: true,
    },
    {
      name: "reset-password-link",
      type: "link",
      label: "Réinitialiser le mot de passe",
      url: "/reset-password",
    },
  ];

  return (
    <PageWrapper showLayout={false}>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        <Grid mt={2}>
          <div className="logo-wrapper">
            <img src={Logo} alt="Logo CFC" />
          </div>
        </Grid>
      </Grid>
      <div className="login-wrapper">
        <FormGenerator
          fields={formFields}
          submitButtonText="Se connecter"
          onSubmit={handleFormSubmit}
        />
      </div>
    </PageWrapper>
  );
}
