import { useEffect, useState } from "react";
import { Field } from "../../components/Form";
import Select from "react-select";
import Tooltip from "../Tooltip";
import onMenuOpen from "../../helpers/onMenuOpen";

interface SelectFieldProps {
  field: Field;
  formik: any;
}

const AbstractSelectField: React.FC<SelectFieldProps> = ({ field, formik }) => {
  let selectRef: any = null;
  const clearValue = () => {
    selectRef?.clearValue();
  };

  let fieldToTrigger: any = null;
  if (field.trigger) {
    fieldToTrigger = formik.values[field.trigger.name];
  }
  useEffect(() => {
    formik.setFieldValue(field.name, field.defaultValue);
  }, [field.defaultValue]);

  useEffect(() => {
    if (!field.defaultValue) {
      formik.setFieldValue(field.name, null);
    }
    if (field?.trigger?.name && field?.trigger?.name !== "") {
      if (
        formik.values[field?.trigger?.name] &&
        formik.values[field?.trigger?.name] !== ""
      ) {
        field?.trigger?.triggerFunc(
          formik.values[field?.trigger?.name],
          formik
        );
      }
    }
    clearValue();
  }, [fieldToTrigger]);

  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    let innerSelectedOption = null;
    let innerOptions: any = field.options;
    if (!field.options || field.options.length < 1) {
      return;
    }
    if (field.options[0].groupName) {
      field.options.forEach((opt) => {
        innerOptions = [...innerOptions, ...opt.options];
      });
    }
    innerSelectedOption = innerOptions.find(
      (option: any) => option.value === formik.values[field.name]
    );
    setSelectedOption(innerSelectedOption);
  }, [formik.values[field.name]]);

  const handleChange = (selected: any) => {
    if (field.handleChange) {
      field.handleChange(selected, formik);
    }
    if (!selected) {
      return;
    }
    if (field.isMulti) {
      const values = selected.map((v: any) => v.value);
      formik.setFieldValue(field.name, values);
      return;
    }
    formik.setFieldValue(field.name, selected.value);
    setSelectedOption(selected);
  };

  let defaultValue: any = null;

  if (field.defaultValue && field.options && field.options.length > 0) {
    if (field.options[0].groupName) {
      let allOptions: any = [];
      field.options.forEach((opt) => {
        allOptions = [...allOptions, ...opt.options];
      });

      defaultValue = allOptions.find(
        (val: any) => val.value === field.defaultValue
      );
    } else {
      defaultValue = field.options.find(
        (val) => val.value === field.defaultValue
      );
    }
  }

  const defaultNoOptionsMessage = () => {
    return "No options";
  };

  return (
    <div
      key={field.name}
      className={`form-group${field.isHidden ? " form-group-is-hidden" : ""}`}
    >
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip && (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          )}
        </label>
      </div>

      <div className="input-wrapper">
        <Select
          key={`my_unique_select_key__${
            defaultValue?.value || selectedOption?.value
          }`}
          ref={(ref) => {
            selectRef = ref;
          }}
          placeholder="Sélection..."
          isMulti={field.isMulti}
          className="form-control form-control-select"
          classNamePrefix="react-select"
          id={field.name}
          defaultValue={defaultValue}
          options={field.options}
          value={selectedOption}
          onChange={handleChange}
          isDisabled={field.isDisabled || field.isReadOnly}
          noOptionsMessage={field.noOptionsMessage || defaultNoOptionsMessage}
          menuIsOpen={field.menuIsOpen}
          onMenuOpen={onMenuOpen}
        />
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default AbstractSelectField;
