import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import List from "../components/List";
import Search from "../components/Search";
import { Grid } from "@mui/material";
import Button from "../components/Button";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Pagination from "../components/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ListBOStructures() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const structureId = searchParams.get("id");

  const [searchKey, setSearchKey] = useState<null | string>("");
  const [structuresData, setStructuresData] = useState<any[]>([]);
  const [structuresEndPoint, setStructuresEndPoint] = useState<string>(
    `/users/my-structures?name=${searchKey}`
  );

  useEffect(() => {
    setStructuresEndPoint(
      structureId
        ? `/users/my-structures?name=${searchKey}&id=${structureId}`
        : `/users/my-structures?name=${searchKey}`
    );
  }, [searchKey]);
  useEffect(() => {
    setStructuresEndPoint(
      `/users/my-structures?name=${searchKey}&id=${structureId}`
    );
  }, [structureId]);

  const handleSearch = (value: string | null) => {
    setSearchKey(value);
  };

  return (
    <PageWrapper>
      <h1>Recherche Structure</h1>
      <Search handleSearch={handleSearch} />
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12} md={12}>
          <List
            title=""
            items={structuresData}
            navigateTo={`/apa/organigram-details`}
            showDropdown={true}
            listStyle="bottomBorder"
          />
          <Pagination
            apiData={structuresEndPoint}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.name} - ${item.typeName}`,
                // name: `${item.name}${item.title ? ` - ${item.title}` : ""}`,
                id: item.id,
                hasChilds: item.hasChilds,
                status: item.status,
                mnt: item.mnt,
                ca: item.ca,
                coutapas: item.coutapas,
                countfolders: item.countfolders,
                title: item.title,
              }));
              if (!areObjectsEqual(formedData, structuresData)) {
                setStructuresData(formedData);
              }
            }}
          />
            <Button
              text="RETOUR"
              size="sm"
              onPress={() => {
                  navigate(-1);
              }}
            />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
