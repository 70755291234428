export default function allNullOrUndefinedValues(obj: any) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== undefined) {
        return false; // Found a non-null and non-undefined value
      }
    }
  }
  return true; // All values are either null or undefined
}
