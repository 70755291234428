import React, { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import List from "../components/List";
import Search from "../components/Search";
import { Grid } from "@mui/material";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Pagination from "../components/Pagination";

export default function ListAPA() {
  const [apaActifsData, setApaActifsData] = useState<any[]>([]);
  const [apaProductorsData, setApaProductorsData] = useState<any[]>([]);
  const [apaProspectsData, setApaProspectsData] = useState<any[]>([]);

  const [searchKey, setSearchKey] = useState<null | string>("");

  const [apaActifsEndPoint, setApaActifsEndPoint] = useState<string>(
    "/apas/categories?category=actifs"
  );
  const [apaProductorsEndPoint, setApaProductorsEndPoint] = useState<string>(
    "/apas/categories?category=productors"
  );
  const [apaProspectsEndPoint, setApaProspectsEndPoint] = useState<string>(
    "/apas/categories?category=prospects"
  );

  useEffect(() => {
    setApaActifsEndPoint(`/apas/categories?category=actifs&name=${searchKey}`);
    setApaProductorsEndPoint(
      `/apas/categories?category=productors&name=${searchKey}`
    );
    setApaProspectsEndPoint(
      `/apas/categories?category=prospects&name=${searchKey}`
    );
  }, [searchKey]);

  const handleSearch = (value: string | null) => {
    if (!value) {
      return;
    }
    setSearchKey(value);
  };

  return (
    <PageWrapper>
      <h1>List APAS</h1>
      <Search handleSearch={handleSearch} />
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12} md={6}>
          <List
            title="APA Prospects"
            items={apaProspectsData}
            navigateTo="/apa/edit-fiche-apa"
            useStateData={true}
          />
          <Pagination
            apiData={apaProspectsEndPoint}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.firstName} ${item.lastName} ${
                  item.structureName ? ` - ${item.structureName}` : ""
                }`,
                id: item["@id"],
              }));
              if (!areObjectsEqual(formedData, apaActifsData)) {
                setApaProspectsData(formedData);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            title="APA Actifs"
            items={apaActifsData}
            navigateTo="/apa/edit-fiche-apa"
            useStateData={true}
          />
          <Pagination
            apiData={apaActifsEndPoint}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.firstName} ${item.lastName} ${
                  item.structureName ? ` - ${item.structureName}` : ""
                }`,
                id: item["@id"],
              }));
              if (!areObjectsEqual(formedData, apaActifsData)) {
                setApaActifsData(formedData);
              }
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <List
            title="APA Producteurs"
            items={apaProductorsData}
            navigateTo="/apa/edit-fiche-apa"
            useStateData={true}
          />
          <Pagination
            apiData={apaProductorsEndPoint}
            getCurrentItems={(current) => {
              const formedData = current.map((item: any) => ({
                name: `${item.firstName} ${item.lastName} ${
                  item.structureName ? ` - ${item.structureName}` : ""
                }`,
                id: item["@id"],
              }));
              if (!areObjectsEqual(formedData, apaActifsData)) {
                setApaProductorsData(formedData);
              }
            }}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
