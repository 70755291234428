const extractIdFromStrings = (input: string | number) => {
  if (!input) {
    console.error("invalid id values", input);
  }
  if (typeof input === "number") {
    return input;
  }

  let parts: string[] = [];
  try {
    parts = input.split("/");
  } catch (error) {
    console.error("invalid id values", input, error);
  }
  return parseInt(parts[parts.length - 1]);
};

export default extractIdFromStrings;
