import React, { useState } from "react";
import Icon from "../components/Icon";
import FormGenerator from "../components/Form";
import Button from "./Button";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import CFCApiHydra from "../apis/CFCApiHydra";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stopLoading, setModel, startLoading } from "../state/slices/appSlice";
import route_links from "../shared/route_links";

export default function SearchFilter({
  handleShareClose,
  type,
  userId
}: {
  handleShareClose?: any;
  type?: string;
  userId?: number | string;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState<null | string>(null);

  const [searchEndpoint, setSearchEndpoint] = useState<string>(
    "/users"
  );

  const handleSubmit = async (values: any) => {
    const { filter_results } = values;
    try {
      dispatch(startLoading());
      const shareResponse = await CFCApiHydra.patch(`/${type}/${userId}/share`, {
        users: filter_results,
      });
      if (shareResponse) {
        dispatch(
          setModel({
            type: "success",
            content: "Partagé avec succès",
            onClose: () => {
              navigate(route_links.espace.url);
              dispatch(setModel(null));
            },
          })
        );
      } else {

        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while sharing",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSearch = async (e: any) => {
    e.preventDefault();
    if (searchKey) {
      if (searchKey !== "") {
        setSearchEndpoint(`/users?name=${searchKey}&page=1`);
      }
    }
  };

  const handleShareWithAdmin = async () => {
    try {
      dispatch(startLoading());

      const notifyResponse = await CFCApiHydra.patch(`/${type}/${userId}/notify`);

      if (notifyResponse) {
        dispatch(
          setModel({
            type: "success",
            content: "Notification bien envoyée",
            onClose: () => {
              dispatch(setModel(null));
            },
          })
        );
        navigate(route_links.espace.url);
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while notifying admin",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }

    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      ); 
    } finally {
      dispatch(stopLoading());
    }

  }

  const [filterFormFields, setFilterFormFields] = useState<any[]>([
    {
      name: "filter_results",
      type: "checkbox",
      label: "",
      options: [],
      grid: {
        xs: 12,
        md: 12,
      },
    },
  ]);

  return (
    <div className={`search-wrapper`}>
      <div className="bg"></div>

      <div className="notify-admin">
        <h2>Envoyer une notification à l'admin</h2>
        <Button text="Envoyer" onPress={handleShareWithAdmin} />
      </div>
      
      <form action="#" onSubmit={handleSearch}>
        <div className="form-group form-group-search">
          <div className="input-wrapper input-wrapper-with-icon">
            <span className="icon">
              <Icon icon="Search" size={20} />
            </span>
            <input
              className="form-control"
              type="text"
              placeholder="Rechercher un Collègue"
              onKeyUp={(e: any) => setSearchKey(e.target.value)}
            />
          </div>
          <div className="action">
            <Button text="search" submit />
            <button className="btn-cancel" onClick={handleShareClose}>
              Annuler
            </button>
          </div>
        </div>
      </form>

      <div className="search-wrapper-results">
        <FormGenerator
          fields={filterFormFields}
          submitButtonText="Valider"
          onSubmit={handleSubmit}
        />
        <Pagination
          apiData={searchEndpoint}
          getCurrentItems={(current) => {
            if (!areObjectsEqual(current, filterFormFields)) {
              setFilterFormFields([
                {
                  name: "filter_results",
                  type: "checkbox",
                  label: "",
                  options: current.map((agent: any) => ({
                    label: `${agent.firstName} ${agent.lastName}`,
                    value: agent["@id"],
                  })),
                  grid: {
                    xs: 12,
                    md: 12,
                  },
                },
              ]);
            }
          }}
        />
      </div>
    </div>
  );
}
