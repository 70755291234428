import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import CFCApi from "../apis/";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import extractIdsFromStrings from "../helpers/extractIdFromStrings";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import route_links from "../shared/route_links";

export default function ShareRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState<null | string>(null);
  const [formError, setFormError] = useState("");

  let {
    state: { contactData },
  } = useLocation();

  console.log("contactData:", contactData);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    if (values["users_filter"] !== undefined) {
      const { users_filter } = values;
      try {
        dispatch(startLoading());
        const shareResponse = await CFCApi.patch(
          `/contacts/${extractIdsFromStrings(contactData.contact["@id"])}/share`,
          {
            users: [users_filter],
          }
        );

        if (shareResponse) {
          dispatch(
            setModel({
              type: "success",
              content: "Partage du dossier effectué avec succès",
              onClose: () => {
                navigate(route_links.espace.url);
                dispatch(setModel(null));
              },
            })
          );
        } else {
          dispatch(
            setModel({
              type: "error",
              content: "Ce contact ne peut être partagé plus que 2 fois.",
              onClose: () => dispatch(setModel(null)),
            })
          );
        }
      } catch (error: any) {
        dispatch(
          setModel({
            type: "error",
            content: error.message,
            onClose: () => dispatch(setModel(null)),
          })
        );
      } finally {
        dispatch(stopLoading());
      }

      setFormError("");
    } else {
      setFormError("Veuillez sélectionner une option.");
    }
  };

  const [formFields] = useState<Field[]>([
    {
      name: "type",
      type: "text",
      label: "Type de contact",
      grid: {
        xs: 12,
        md: 6,
      },
      defaultValue: contactData.contact["@type"],
    },
    {
      name: "status",
      type: "select",
      label: "Statut",
      options: [
        { label: "Prise de contact", value: "making_contact" },
        { label: "Faisabilité", value: "feasibility" },
        { label: "étude à distance", value: "remote_study" },
        { label: "étude à domicile", value: "home_study" },
        { label: "Injoignable", value: "unreachable" },
        { label: "Sans suite client", value: "without_customer_followup" },
        {
          label: "Sans suite hors normes",
          value: "without_extraordinary_followup",
        },
        { label: "Refus bo", value: "rejected_bo" },
        { label: "Refus banque", value: "rejected_bank" },
        { label: "Envoi Bo", value: "send_bo" },
        { label: "Accord", value: "agreement" },
        { label: "Financé", value: "financed" },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      defaultValue: contactData.contact.status,
    },
    {
      name: "apa_contact",
      type: "text",
      label: "APA Commercial",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      defaultValue: `${contactData.contact.apa.firstName} ${contactData.contact.apa.lastName}`,
    },
    {
      name: "source_user",
      type: "text",
      label: "Demandeur",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      defaultValue: `${contactData.sourceUser.firstName} ${contactData.sourceUser.lastName}`,
    },
    {
      name: "structure",
      type: "text",
      label: "Structure",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      defaultValue: contactData.contact.apa.structureName || "",
    },
  ]);

  const [shareFormFields, setShareFormFields] = useState<Field[]>([
    {
      name: "users_filter",
      type: "radio",
      label: "",
      options: [],
      grid: {
        xs: 12,
        md: 12,
      },
    },
  ]);

  const handleSearch = (value: string | null) => {
    if (!value) {
      return;
    }
    setSearchKey(value);
  };

  return (
    <PageWrapper>
      <div className="share-request">
        <h1>Demande de partage : {contactData.contact.firstName} {contactData.contact.lastName}</h1>
        <FormGenerator fields={formFields} isReadOnly />

        <h2>Partager le dossier</h2>

        <div className="share-request-search">
          <Search
            handleSearch={handleSearch}
            placeholder="Rechercher un utilisateur"
          />
        </div>

        <div className="share-request-results">
          {searchKey && (
            <>
              {formError && (
                <div className="form-group-error text-center">{formError}</div>
              )}

              {shareFormFields[0].options &&
              shareFormFields[0].options.length > 0 ? (
                <FormGenerator
                  fields={shareFormFields}
                  submitButtonText="Valider"
                  onSubmit={handleFormSubmit}
                />
              ) : (
                <div className="text-center">Aucun résultat trouvé.</div>
              )}

              <Pagination
                apiData={`/users?name=${searchKey}`}
                getCurrentItems={(current) => {
                  if (!areObjectsEqual(current, shareFormFields)) {
                    setShareFormFields([
                      {
                        name: "users_filter",
                        type: "radio",
                        label: "",
                        options: current.map((user: any) => ({
                          label: `${user.firstName} ${user.lastName} (${user.job})`,
                          value: user["@id"],
                        })),
                        grid: {
                          xs: 12,
                          md: 12,
                        },
                      },
                    ]);
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}
