import { Link, NavLink } from "react-router-dom";
import Icon from "./Icon";
import Logo from "../assets/images/logo-cfc-small.svg";
import route_links from "../shared/route_links";

const NavMenu: React.FC = () => {
  return (
    <nav className="nav">
      <div className="top-header-logo-wrapper">
        <Link to="/">
          <img src={Logo} alt="" />
        </Link>
      </div>
      <ul className="nav-list">
        <li className="nav-item">
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              `nav-link ${isPending ? "pending" : isActive && "active"}`
            }
          >
            <span className="nav-icon">
              <Icon icon="home" size={22} />
            </span>
            <span className="nav-text">Home</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/apa"
            className={({ isActive, isPending }) =>
              `nav-link ${isPending ? "pending" : isActive && "active"}`
            }
          >
            <span className="nav-icon">
              <Icon icon="user-settings" size={22} />
            </span>
            <span className="nav-text">APA</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={route_links.espace.url}
            className={({ isActive, isPending }) =>
              `nav-link nav-link--small ${
                isPending ? "pending" : isActive && "active"
              }`
            }
          >
            <span className="nav-icon">
              <Icon icon="folder" size={22} />
            </span>
            <span className="nav-text">{route_links.espace.name}</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/statistics"
            className={({ isActive, isPending }) =>
              `nav-link ${isPending ? "pending" : isActive && "active"}`
            }
          >
            <span className="nav-icon">
              <Icon icon="statistics" size={22} />
            </span>
            <span className="nav-text">Stats</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/agenda"
            className={({ isActive, isPending }) =>
              `nav-link ${isPending ? "pending" : isActive && "active"}`
            }
          >
            <span className="nav-icon">
              <Icon icon="calendar" size={22} />
            </span>
            <span className="nav-text">Agenda</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
