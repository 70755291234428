import React, { useState } from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

interface PasswordFieldProps {
  field: Field;
  formik: any;
}

const PasswordField: React.FC<PasswordFieldProps> = ({ field, formik }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>
      <div className="input-wrapper">
        <input
          {...formik.getFieldProps(field.name)}
          className="form-control"
          type={showPassword ? "text" : "password"}
          // type={field.type}
          id={field.name}
          value={formik.getFieldProps(field.name).value || ""}
        />
        {!field.isEditable && (
          <button
            className="password-icon"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <Icon icon="eye-slash" size={18} />
            ) : (
              <Icon icon="eye" size={20} />
            )}
          </button>
        )}
        {field.isEditable && (
          <span className="editable-icon">
            <Icon icon="note-pencil" size={15} />
          </span>
        )}
      </div>
      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default PasswordField;
