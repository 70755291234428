import React from "react";
import Icon from "./Icon";
import { Link } from "react-router-dom";
import extractIdsFromStrings from "../helpers/extractIdFromStrings";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

interface AppointmentProps {
  appointment: any;
}

const Appointment: React.FC<AppointmentProps> = ({ appointment }) => {
  let date: any = appointment.appointmentDate
    ? moment(appointment.appointmentDate)
    : null;
  let time: any = appointment.startHour ? moment(appointment.startHour).local() : null;

  const id = extractIdsFromStrings(appointment["@id"]);
  date = date.format("dddd DD MMMM");
  time = time.format("HH:mm");
  const details = appointment.title;
  let client = null;

  if (appointment?.apa) {
    client = `${appointment.apa.firstName} ${appointment.apa.lastName}`;
  } else if (appointment?.contact) {
    client = `${appointment.contact.firstName} ${appointment.contact.lastName}`;
  }

  return (
    <Link
      to={appointment.linkTo ? appointment.linkTo : "/agenda/edit-rdv"}
      state={{ appointmentData: { id, ...appointment } }}
    >
      <div className="appointment">
        <div className="appointment-header">
          {date && (
            <div className="appointment-date">
              <Icon icon="calendar" size={14} />
              <span className="appointment-date-text">{date}</span>
            </div>
          )}
          {time && (
            <div className="appointment-time">
              <Icon icon="time" size={15} />
              <span className="appointment-time-text">{time}</span>
            </div>
          )}
        </div>
        <div className="appointment-content">
          {details && <div className="appointment-details">{details}</div>}
          {client && (
            <div className="appointment-client">
              <Icon icon="user" size={14} />
              <span className="appointment-client-text">{client}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Appointment;
