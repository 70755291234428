import PageWrapper from "../components/Layout/PageWrapper";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import FormGenerator from "../components/Form";
import CFCApi from "../apis/";
import ButtonLink from "../components/ButtonLink";
import Grid from "../components/Layout/Grid";
import useContact from "../hooks/useContact";
import useTrigger from "../hooks/useTrigger";
import route_links from "../shared/route_links";

export default function EditCoEmprunteur() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const contactId = searchParams.get("contactId");

  const contactValues = location.state.formValues;

  const { triggerModel, triggerError } = useTrigger();
  const { editCoEmprunteurFields } = useContact(
    { id: contactId, ...contactValues },
    location?.state?.data,
    true
  );

  const handleFormSubmit = (values: { [key: string]: string | string[] }) => {
    navigate(
      `/edit-${contactValues.externalApaFolderNumber ? "dossier" : "contact"}`,
      {
        state: {
          coEmprunteurValues: values,
          contactValues: { id: contactId, ...contactValues },
        },
      }
    );
  };

  const handleClick = async () => {
    try {
      dispatch(startLoading());
      const response = await CFCApi.patch(`/contacts/${contactId}/switch`);
      dispatch(stopLoading());
      if (response) {
        triggerModel(
          "success",
          "Le co-emprunteur est bien devenu l'emprunteur principal",
          () => {
            dispatch(setModel(null));
            navigate(route_links.espace.url);
          }
        );
        return;
      }
      throw new Error(
        "Une erreur inattendue s'est produite lors de la procédure pour devenir emprunteur principal."
      );
    } catch (error: any) {
      triggerError(error);
    }
  };

  return (
    <PageWrapper>
      <h1>Infos co-emprunteur </h1>
      {contactId && (
        <ButtonLink
          text="Devenir emprunteur principal"
          icon="eye"
          onClick={handleClick}
        />
      )}
      {editCoEmprunteurFields && (
        <Grid container spacing={0}>
          <Grid>
            <FormGenerator
              fields={editCoEmprunteurFields}
              submitButtonText="Valider"
              widthReturn
              onSubmit={handleFormSubmit}
            />
          </Grid>
        </Grid>
      )}
    </PageWrapper>
  );
}
