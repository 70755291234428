import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useNavigate } from "react-router-dom";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import FormGenerator, { Field } from "../components/Form";
import useTrigger from "../hooks/useTrigger";

export default function AddAccesCommerciaux() {
  const navigate = useNavigate();
  const { triggerLoading, triggerModel } = useTrigger();

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      triggerLoading(true);
      const response = await CFCApi.post("/users", values);
      if (response) {
        triggerModel("success", "Compte créé avec succès", () => {
          navigate("/");
        });
      } else {
        throw new Error("An unexpected error occurred while creating User");
      }
    } catch (error: any) {
      triggerModel("error", error.message);
    } finally {
      triggerLoading(false);
    }
  };

  useEffect(() => {
    const getFormDynamicValues = async () => {
      try {
        triggerLoading(true);
        const users = await CFCApiHydra.get("/users?pagination=false");
        const agencies = await CFCApiHydra.get(`/agencies`);

        if (users["hydra:member"]) {
          let formFieldsUpdated = [...formFields];
          formFieldsUpdated[5].options = users["hydra:member"].map(
            (opt: any) => ({
              label: `${opt.firstName} ${opt.lastName}`,
              value: opt["@id"],
            })
          );
          setFormFields(formFieldsUpdated);
        } else {
          throw new Error();
        }

        if (agencies["hydra:member"]) {
          formFields[6].options = agencies["hydra:member"].map((agc: any) => ({
            label: agc.name,
            value: agc["@id"],
          }));
        } else {
          throw new Error(
            "An unexpected error occurred while getting Agences data"
          );
        }
      } catch (error: any) {
        triggerModel("error", error.message);
      } finally {
        triggerLoading(false);
      }
    };
    getFormDynamicValues();
  }, []);

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "lastName",
      type: "text",
      label: "Nom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "job",
      type: "radio",
      label: "Poste",
      options: [
        { label: "Commercial", value: "commercial" },
        { label: "Manager", value: "manager" },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "title",
      type: "select",
      label: "Titre",
      options: [
        { label: "Directeur Général", value: "GeneralManager" },
        { label: "Superviseur", value: "Supervisor" },
        { label: "Directeur d'Agence", value: "AgencyDirector" },
        { label: "Responsable de secteur", value: "AreaManager" },
        { label: "Chargé d'Affaire", value: "ResponsibleBusiness" },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "parent",
      type: "select",
      label: "Rattachement",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "agency",
      type: "select",
      label: "Agence",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
  ]);

  return (
    <PageWrapper>
      <h2>Création accès commerciaux</h2>
      <div className="acces-commerciaux-form">
        <FormGenerator
          fields={formFields}
          submitButtonText="SAUVEGARDER ET Générer le mot de passe"
          widthReturn
          onSubmit={handleFormSubmit}
        />
      </div>
    </PageWrapper>
  );
}
