const onMenuOpen = () => {
  setTimeout(() => {
    const optionsHeading = document.getElementsByClassName(
      "react-select__group-heading"
    );

    const handleClick = (event: any) => {
      const parentNode = event.target.parentNode;
      if (parentNode) {
        parentNode.classList.toggle("show");
      }
    };

    Array.from(optionsHeading).forEach((heading) => {
      heading.addEventListener("click", handleClick);
    });
  }, 0);
};

export default onMenuOpen;
