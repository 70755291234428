import React, {useEffect, useState} from "react";
import {SyncLoader} from "react-spinners";

export default function Loading() {
    const [loaderSize, setLoaderSize] = useState(20);
    const handleResize = () => {
        const screenSize = window.innerWidth;
        let newSize = 20;
        if (screenSize <= 600) {
            newSize = 15;
        }
        setLoaderSize(newSize);
    };
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
  return (
    <div className="loading-overlay">
      <SyncLoader
        color="#44BABC"
        loading={true}
        size={loaderSize}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
