import Cookie from "js-cookie";

const logout = (callBack: any) => {
  Cookie.remove("token");
  Cookie.remove("g-token");
  Cookie.remove("switchuser");
  callBack();
  window.location.href = "/";
};

export default logout;
