import { useEffect, useState, ReactNode } from "react";
import { Container } from "@mui/material";
import NavMenu from "../NavMenu";
import TopHeader from "../Topheader";

export default function PageWrapper({
  children,
  showLayout = true,
}: {
  children: ReactNode;
  showLayout?: boolean;
}) {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    // Cancle Pending Reqs in route change
    //CFCApiHydra.cancelReqs();
    setShow(true);
  }, []);

  return show ? (
    <div className="page-wrapper">
      <Container maxWidth="lg">
        {showLayout && <TopHeader />}
        <div className="page-content">{children}</div>
        {showLayout && <NavMenu />}
      </Container>
    </div>
  ) : null;
}
