const isNumber = (value: number | string) => {
  if (typeof value === "number") {
    return true;
  }

  if (typeof value === "string") {
    return !isNaN(parseFloat(value));
  }
  return false;
};

export default isNumber;
