import React from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

interface UploadFieldProps {
  field: Field;
  formik: any;
}

const UploadField: React.FC<UploadFieldProps> = ({ field, formik }) => {
  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip && (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          )}
        </label>
        {field.action && (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        )}
      </div>

      <div>
        {field.uploadAction && (
          <div
            className="input-wrapper input-wrapper-upload"
            onClick={field.uploadAction.handler}
          >
            <div className="form-control form-control-upload">
              <span>{field.defaultValue || ""}</span>
            </div>
            {!field.isEditable && (
              <span className="editable-icon">
                <Icon icon="paperclip" size={16} />
              </span>
            )}
            {field.isEditable && (
              <span className="editable-icon">
                <Icon icon="note-pencil" size={15} />
              </span>
            )}
          </div>
        )}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] && (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      )}
    </div>
  );
};

export default UploadField;
