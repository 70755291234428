import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import CFCApiHydra from "../apis/CFCApiHydra";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import { useDispatch } from "react-redux";
import Button from "../components/Button";

export default function AddBOStructures() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rootStructures, setRootStructures] = useState<{ name: string }[]>([]);
  useEffect(() => {
    dispatch(startLoading());
    (async () => {
      const rootStructuresRes = await CFCApiHydra.get("/structure_root_types?pagination=false");
      if (rootStructures) {
        setRootStructures(rootStructuresRes["hydra:member"]);
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "erreur lors de l'obtention de la structure racine",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
      dispatch(stopLoading());
    })();
  }, []);

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12} justifyContent="center">
          <div className="setting-actions">
            <h3>Création des structures</h3>
            {rootStructures.map((rootStructure) => (
              <Link
                className="btn"
                state={{ rootStructure }}
                to="/create-bo-strucutre"
              >
                {rootStructure.name}
              </Link>
            ))}
            <Link
              className="btn"
              state={{ rootStructures }}
              to="/create-bo-strucutre-type"
            >
              Créer un type de structure
            </Link>
            <Button
            text="RETOUR"
              onPress={() => navigate(-1)}
           />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
