import React from "react";
import { Link } from "react-router-dom";

interface LinkFieldProps {
  label: string;
  url?: string;
  formik: any;
}

const LinkField: React.FC<LinkFieldProps> = ({ label, url }) => {
  if (url) {
    return (
      <div className="form-group">
        <Link to={url} className="link-primary">
          {label}
        </Link>
      </div>
    );
  } else {
    return null;
  }
};

export default LinkField;
