import Logo from "./../assets/images/logo-cfc-small.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import Cookie from "js-cookie";
import NavMenu from "./NavMenu";
import JWTDecode from "../helpers/JWTDecode";
import { useDispatch } from "react-redux";
import { setUser } from "../state/slices/userSlice";
import logout from "../helpers/logout";

const TopHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user: any = JWTDecode();

  // TODO: Change Geting user with JWTDecode and get it from Redux
  // const user2 = useSelector((state: any) => state.user.data);
  // console.log("user2", user2);

  const switchUser = Cookie.get("switchuser");

  const handleExitImpersonation = () => {
    Cookie.remove("switchuser");
    window.location.href = "/";
  };

  return (
    <div className="top-header">
      {switchUser && (
        <div className="switchuser-banner">
          <Button
            text="Revenir à mon compte"
            size="sm"
            icon="xmark"
            onPress={() => handleExitImpersonation()}
          />
        </div>
      )}

      <div className="top-header-wrapper">
        <div className="top-header-logo-wrapper">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="top-header-desk">
          <NavMenu />
          {user?.roles?.includes("ROLE_ADMIN") && (
            <div>
              <Link className="btn btn-settings" to="/settings">
                Paramètrage
              </Link>
            </div>
          )}
          <div className="top-header-buttons">
            <Button icon="user" to={() => navigate("/profile")} />
            <Button
              className="logout"
              iconSize={20}
              icon="logout"
              to={() =>
                logout(() => {
                  dispatch(setUser(null));
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
