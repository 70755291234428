import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";

const useTrigger = () => {
  const dispatch = useDispatch();

  const triggerLoading = (loading: boolean) => {
    if (loading) {
      dispatch(startLoading());
    } else {
      dispatch(stopLoading());
    }
  };

  const triggerError = (error: any) => {
    dispatch(stopLoading());
    triggerModel("error", error.message, () => {
      dispatch(setModel(null));
    });
  };

  const triggerModel = (
    type: "success" | "error" | null,
    content?: string,
    onClose?: () => void,
    action?: {
      text: string;
      func: () => void;
    } | null
  ) => {
    if (type === null) {
      dispatch(setModel(null));
      return;
    }
    const handleClose = () => {
      dispatch(setModel(null));
      if (onClose) {
        onClose();
      }
    };
    const handleAction = () => {
      dispatch(setModel(null));
      if (action?.func) {
        action.func();
      }
    };
    dispatch(
      setModel({
        type,
        content,
        onClose: handleClose,
        action: {
          text: action?.text,
          func: handleAction,
        },
      })
    );
  };

  return { triggerModel, triggerError, triggerLoading };
};

export default useTrigger;
