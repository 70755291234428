import Button from "../components/Button";
import Icon from "../components/Icon";

export default function NomenclatureForm({
  formData,
  setFormData,
  handleInputChange,
  handleAddSubCategory,
  handleDelete,
}: {
  formData: any;
  setFormData: any;
  handleInputChange: any;
  handleAddSubCategory: any;
  handleDelete?: (item: any, isParent?: boolean) => void;
}) {
  return (
    <div className="nomenclatures-form">
      <div className="nomenclatures-form-parent">
        <div className="form-group">
          <div className="input-wrapper">
            <input
              className="form-control"
              type="text"
              name="category"
              value={formData.category}
              onChange={(e) =>
                setFormData({ ...formData, category: e.target.value })
              }
            />
            <span className="editable-icon">
              <Icon icon="note-pencil" size={15} />
            </span>
          </div>

          <div className="actions-wrapper">
            {handleDelete && (
              <Button
                onPress={() => handleDelete(formData, true)}
                iconSize={12}
                icon="trash"
              />
            )}
          </div>
        </div>
      </div>

      <div className="nomenclatures-form-subcategories">
        {formData.subCategories.map((subCategory: any, index: any) => (
          <div className="form-group" key={index}>
            <div className="input-wrapper">
              <input
                className="form-control"
                type="text"
                name="name"
                value={subCategory.name}
                onChange={(e) => handleInputChange(e, index)}
              />
              <span className="editable-icon">
                <Icon icon="note-pencil" size={15} />
              </span>
            </div>
            <div className="actions-wrapper">
              {handleDelete && subCategory["@id"] && (
                <Button
                  onPress={() => handleDelete(subCategory)}
                  iconSize={12}
                  icon="trash"
                />
              )}
            </div>
          </div>
        ))}
        <Button
          text="Ajouter"
          size="sm"
          icon="plus"
          onPress={handleAddSubCategory}
        />
      </div>
    </div>
  );
}
