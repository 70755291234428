import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Organigram from "../components/Organigram";
import { Grid } from "@mui/material";
import Button from "../components/Button";
import CFCApiHydra from "../apis/CFCApiHydra";
import { useDispatch } from "react-redux";
import { setModel } from "../state/slices/appSlice";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import useUsers from "../hooks/useUsers";

function DetailsOrganigram() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isAdmin } = useUsers();

  const locationState = location.state;

  const organigrammeId: any = searchParams.get("id");
  const organigrammeType: any = searchParams.get("type");

  const [organigramData, setOrganigramData] = useState<any>(null);
  const [showEditStructure, setShowEditStructure] = useState<any>(false);
  const [showEditLevel, setShowEditLevel] = useState<any>(false);

  const [loading, setLoading] = useState(false);

  const typeOrganigram = locationState?.navigateToParam || "apa";

  useEffect(() => {
    if (locationState?.contactData) {
      fetchOrganigramData(
        extractIdFromStrings(locationState.contactData.id),
        typeOrganigram
      );
    }
  }, []);

  useEffect(() => {
    if (organigrammeId) {
      fetchOrganigramData(organigrammeId, organigrammeType);
    }
  }, [organigrammeId]);

  const fetchOrganigramData = async (id: any, type: string) => {
    try {
      setLoading(true);
      const response = await CFCApiHydra.get(
        `/apas/${id}/organigram?for=${type}`
      );
      if (response) {
        setOrganigramData(response);
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while getting Organigram",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setModel({
          type: "error",
          content: err.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = (id: number) => {
    navigate(
      `/apa/organigram-details?id=${id}&type=${
        organigrammeType || typeOrganigram
      }`
    );
  };

  useEffect(() => {
    if (
      isAdmin &&
      organigramData?.center?.isRootLevel &&
      organigrammeType !== "apa"
    ) {
      setShowEditStructure(true);
    } else {
      setShowEditStructure(false);
    }

    if (
      organigramData?.center?.canEdit &&
      !organigramData?.center?.isRootLevel &&
      organigrammeType !== "apa"
    ) {
      setShowEditLevel(true);
    } else {
      setShowEditLevel(false);
    }
  }, [organigramData]);

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12}>
          <h1 className="text-center">L’organigramme</h1>

          {loading ? (
            <div className="organigram-loader">Loading...</div>
          ) : (
            <div>
              {organigramData ? (
                <Organigram
                  data={organigramData}
                  showDetails={true}
                  onItemClick={handleItemClick}
                  isEditableAPA={isAdmin ? true : false}
                  isEditableStructure={
                    (isAdmin && organigramData?.center?.isRootLevel) ||
                    organigramData?.center?.canEdit
                  }
                  isRootLevel={organigramData?.center?.isRootLevel}
                  rootStructureId={locationState?.contactData?.id}
                />
              ) : (
                <div className="organigram-error">Organigramme non trouvé</div>
              )}
            </div>
          )}

          <div className="organigram-buttons">
            {showEditStructure && (
              <div className="text-center">
                <Button
                  text="MODIFIER LA STRUCTURE"
                  onPress={() =>
                    navigate("/create-bo-strucutre", {
                      state: {
                        rootStructureId: organigramData?.center?.structureId,
                      },
                    })
                  }
                />
              </div>
            )}

            {showEditLevel && (
              <div className="text-center">
                <Button
                  text="MODIFIER NIVEAU"
                  onPress={() =>
                    navigate("/apa/edit-structure", {
                      state: {
                        contactData: {
                          id: organigramData?.center.structureId,
                        },
                      },
                    })
                  }
                />
              </div>
            )}

            <div className="text-center">
              <Button text="RETOUR" onPress={() => navigate(-1)} />
            </div>
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
export default DetailsOrganigram;
