import { useEffect, useState } from "react";
import { Field } from "../../components/Form";
import Select from "react-select";
import Tooltip from "../Tooltip";
import onMenuOpen from "../../helpers/onMenuOpen";

interface SelectFieldProps {
  field: Field;
  formik: any;
}

const SelectField: React.FC<SelectFieldProps> = ({ field, formik }) => {
  const innerSelectedOption = field.options?.find(
    (option) => option.value === formik.values[field.name]
  );
  const [selectedOption, setSeletedOptions] = useState(innerSelectedOption);

  const handleChange = (selected: any) => {
    if (field.handleChange) {
      field.handleChange(selected, formik);
    }
    if (!selected) {
      return;
    }
    if (field.isMulti) {
      const values = selected.map((v: any) => v.value);
      formik.setFieldValue(field.name, values);
      setSeletedOptions(selected);
      return;
    }
    formik.setFieldValue(field.name, selected.value);
    setSeletedOptions(selected);
  };

  useEffect(() => {
    let innerSelectedOption: any = [];
    if (field.defaultValue && field.options) {
      if (field.isMulti) {
        field.defaultValue.forEach((val: string) => {
          if (!field.options) return;
          field.options.forEach((opt: any) => {
            if (opt.value === val) {
              innerSelectedOption.push(opt);
            }
          });
        });
        formik.setFieldValue(field.name, field.defaultValue);
      } else {
        innerSelectedOption = field.options.find(
          (val: any) => val.value === field.defaultValue
        );
        formik.setFieldValue(field.name, field.defaultValue);
      }
      setSeletedOptions(innerSelectedOption);
      if (field.handleChange) {
        field.handleChange(selectedOption, formik);
      }
    }
  }, [field.defaultValue]);

  const defaultNoOptionsMessage = () => {
    return "No options";
  };

  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>

      <div className="input-wrapper">
        <Select
          className={`form-control form-control-select ${
            field.highlight ? "input-wrapper-highlight" : ""
          }`}
          classNamePrefix="react-select"
          placeholder="Sélection..."
          id={field.name}
          isMulti={field.isMulti}
          options={field.options}
          value={selectedOption}
          onChange={handleChange}
          isDisabled={field.isDisabled || field.isReadOnly}
          noOptionsMessage={field.noOptionsMessage || defaultNoOptionsMessage}
          menuIsOpen={field.menuIsOpen}
          onMenuOpen={onMenuOpen}
          isOptionDisabled={(option) => option.disabled}
        />
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default SelectField;
