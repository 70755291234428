import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import FormGenerator, { Field } from "../components/Form";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import { Grid } from "@mui/material";
import useTrigger from "../hooks/useTrigger";

export default function EditAccesCommerciaux() {
  const navigate = useNavigate();
  const { triggerModel, triggerLoading } = useTrigger();

  const location = useLocation();
  const user = location.state;

  const [showForm, setShowForm] = useState<boolean>(false);

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      triggerLoading(true);
      const StructureEditResponse = await CFCApi.patch(
        `/users/${user.id}`,
        values
      );

      if (StructureEditResponse) {
        triggerModel("success", "Mis à jour avec succés", () => {
          navigate("/acces-commerciaux");
        });
      } else {
        triggerModel(
          "error",
          "An unexpected error occurred while updating user"
        );
      }
    } catch (error: any) {
      triggerModel("error", error.message);
    } finally {
      triggerLoading(false);
    }
  };

  useEffect(() => {
    triggerLoading(true);

    const getFormDynamicValues = async () => {
      Promise.all([
        CFCApiHydra.get("/users?pagination=false"),
        CFCApiHydra.get(`/users/${user.id}`),
        CFCApiHydra.get(`/agencies`),
      ])
        .then(async ([UsersResponse, UserResponse, AgencesRespnse]) => {
          triggerLoading(false);

          try {
            const formFieldsUpdated = formFields;
            const formAsignFieldsUpdated = formAsignFields;

            if (UsersResponse["hydra:member"]) {
              let formFieldsUpdated = formFields;
              const formatedData = UsersResponse["hydra:member"].map(
                (opt: any) => ({
                  label: `${opt.firstName} ${opt.lastName}`,
                  value: opt["@id"],
                })
              );
              formFieldsUpdated[5].options = formatedData;
              formAsignFieldsUpdated[0].options = formatedData;
            } else {
              triggerModel(
                "error",
                "An unexpected error occurred while getting users"
              );
            }

            if (UserResponse) {
              for (let key in UserResponse) {
                switch (key) {
                  case "lastName":
                    formFieldsUpdated[0].defaultValue = UserResponse[key];
                    break;
                  case "firstName":
                    formFieldsUpdated[1].defaultValue = UserResponse[key];
                    break;
                  case "email":
                    formFieldsUpdated[2].defaultValue = UserResponse[key];
                    break;
                  case "job":
                    formFieldsUpdated[3].defaultValue = UserResponse[key];
                    break;
                  case "title":
                    formFieldsUpdated[4].defaultValue = UserResponse[key];
                    break;
                  case "parent":
                    formFieldsUpdated[5].defaultValue =
                      UserResponse[key]["@id"];
                    break;
                  case "agency":
                    formFieldsUpdated[6].defaultValue = UserResponse[key];
                    break;

                  default:
                }
              }

              setFormFields(formFieldsUpdated);
              setFormAsignFields(formAsignFieldsUpdated);
              setShowForm(true);
            } else {
              throw new Error(
                "An unexpected error occurred while getting data"
              );
            }

            if (AgencesRespnse["hydra:member"]) {
              formFields[6].options = AgencesRespnse["hydra:member"].map(
                (agc: any) => ({
                  label: agc.name,
                  value: agc["@id"],
                })
              );
            } else {
              throw new Error(
                "An unexpected error occurred while getting Agences data"
              );
            }
          } catch (error: any) {
            throw error;
          }
        })
        .catch((error: any) => {
          triggerLoading(false);
          triggerModel("error", error.message);
        });
    };
    getFormDynamicValues();
  }, []);

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "lastName",
      type: "text",
      label: "Nom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isReadOnly: true,
    },
    {
      name: "job",
      type: "radio",
      label: "Poste",
      options: [
        { label: "Commercial", value: "commercial" },
        { label: "Manager", value: "manager" },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "title",
      type: "select",
      label: "Titre",
      options: [
        { label: "Directeur Général", value: "GeneralManager" },
        { label: "Superviseur", value: "Supervisor" },
        { label: "Directeur d'Agence", value: "AgencyDirector" },
        { label: "Responsable de secteur", value: "AreaManager" },
        { label: "Chargé d'Affaire", value: "ResponsibleBusiness" },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "parent",
      type: "select",
      label: "Rattachement",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "agency",
      type: "select",
      label: "Agence",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
  ]);

  const [formAsignFields, setFormAsignFields] = useState<Field[]>([
    {
      name: "user",
      type: "select",
      label: "Utilisateur",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "agence",
      type: "abstractSelect",
      isMulti: true,
      label: "Agences",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      trigger: {
        name: "user",
        triggerFunc: (val: string) => autoFillAgenciesOptions(val),
      },
      required: true,
    },
  ]);

  const autoFillAgenciesOptions = async (val: string) => {
    if (!val) {
      return;
    }
    triggerLoading(true);

    const agenciesResponse = await CFCApiHydra.get(
      `/users/${extractIdFromStrings(val)}/structures?level=Agence`
    );
    if (agenciesResponse && agenciesResponse["hydra:member"]) {
      const updatedFormAsignField = formAsignFields;
      updatedFormAsignField[1].defaultValue = null;
      updatedFormAsignField[1].options = agenciesResponse["hydra:member"].map(
        (agc: any) => ({
          label: agc.name,
          value: agc["@id"],
        })
      );
      setFormAsignFields(updatedFormAsignField);
    }
    triggerLoading(false);
  };

  const handleFormSignSubmit = async (v: any) => {
    triggerLoading(true);
    const submitResp = await CFCApiHydra.patch(
      `/users/${extractIdFromStrings(v.user)}/assigne`,
      {
        structures: v.agence,
      }
    );
    triggerLoading(false);
    if (submitResp.codeStatus !== 200) {
      triggerModel("error", "Error occur");
      return;
    }
    triggerModel("success", "Rapatrié avec succès");
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={6}>
          <h2>Modification accès commerciaux</h2>
          <div className="acces-commerciaux-form">
            {showForm && (
              <FormGenerator
                fields={formFields}
                submitButtonText="SAUVEGARDER"
                widthReturn
                onSubmit={handleFormSubmit}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} pl={3}>
          <h2>Réattribution Agence</h2>
          <div className="assgignment-form">
            {showForm && (
              <FormGenerator
                fields={formAsignFields}
                submitButtonText="SAUVEGARDER"
                onSubmit={handleFormSignSubmit}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
