import React from "react";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import Logo from "../assets/images/cfc-logo.png";
import CFCApi from "../apis";
import { Grid } from "@mui/material";

export default function ResetPassword() {
  const dispatch = useDispatch();

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      dispatch(startLoading());
      const response = await CFCApi.patch("/users/reset-password", {
        email: values.email,
      });
      
      
      if (response) {
        dispatch(
          setModel({
            type: "success",
            content:
              "veuillez vérifier votre e-mail pour les nouveaux détails d'accès",
            onClose: () => {
              dispatch(setModel(null));
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while resetting password",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      // ResetPassword failed
      
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const formFields: Field[] = [
    {
      name: "email",
      type: "email",
      label: "Email",
      required: true,
    },
    {
      name: "login",
      type: "link",
      label: "Back to Login",
      url: "/",
    },
  ];

  return (
    <PageWrapper showLayout={false}>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        <Grid mt={2}>
          <div className="logo-wrapper">
            <img src={Logo} alt="Logo CFC" />
          </div>
        </Grid>
      </Grid>

      <div className="login-wrapper">
        <FormGenerator
          fields={formFields}
          submitButtonText="Envoyer"
          onSubmit={handleFormSubmit}
        />
      </div>
    </PageWrapper>
  );
}
