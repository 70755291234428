import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import CFCApi from "../apis";
import CFCApiHydra from "../apis/CFCApiHydra";
import FormGenerator, { Field } from "../components/Form";
import extractIdFromStrings from "../helpers/extractIdFromStrings";

export default function AddAPA() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const autoFillSiteOptions = async (
    val: any,
    formik: any,
    structureRepValue?: string
  ) => {
    formik.setFieldValue("structure", null);
    const { structureRep } = formik.values;

    let jobId = val || formik.values.job;
    let structureRepId = structureRepValue || structureRep;
    if (!jobId || !structureRepId) return;

    jobId = extractIdFromStrings(jobId);
    structureRepId = extractIdFromStrings(structureRepId);

    dispatch(startLoading());

    try {
      const structures = await CFCApiHydra.get(
        `/structures?pagination=false&job=${jobId}&rootStructure=${structureRepId}`
      );

      if (structures["hydra:member"]) {
        let formFieldsUpdated = [...formFields];
        formFieldsUpdated[2].label =
          structures["hydra:member"][0]?.typeName || "Site";
        formFieldsUpdated[2].options = structures["hydra:member"].map(
          (opt: any) => ({
            label: opt.name,
            value: opt["@id"],
          })
        );
        setFormFields(formFieldsUpdated);
      } else {
        dispatch(
          setModel({
            type: "error",
            content:
              "Une erreur inattendue s'est produite lors de la récupération des données.",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content:
            error.message ||
            "Une erreur inattendue s'est produite lors de la récupération des données.",
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      dispatch(startLoading());
      const response = await CFCApi.post("/apas", values);
      if (response) {
        dispatch(
          setModel({
            type: "success",
            content: "APA créé(e) avec succès",
            onClose: () => {
              navigate("/");
              dispatch(setModel(null));
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content:
              "Une erreur inattendue s'est produite lors de la création APA commercial.",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    const getFormDynamicValues = async () => {
      try {
        dispatch(startLoading());
        const jobs = await CFCApiHydra.get("/jobs?pagination=false");
        const structuresRep = await CFCApiHydra.get(
          "/structures/subStructures?pagination=false&dropDown=1&id=0"
        );

        if (jobs["hydra:member"] && structuresRep.substructures) {
          let formFieldsUpdated = [...formFields];
          formFieldsUpdated[0].options = jobs["hydra:member"].map(
            (opt: any) => ({
              label: opt.name,
              value: opt["@id"],
            })
          );

          formFieldsUpdated[1].options = structuresRep.substructures
            .filter((sub: any) => sub)
            .map((opt: any) => ({
              label: opt.name,
              value: opt["@id"],
            }));
          setFormFields(formFieldsUpdated);
        } else {
          throw new Error();
        }
      } catch {
        dispatch(
          setModel({
            type: "error",
            content:
              "Une erreur inattendue s'est produite lors de la récupération des options de champ.",
            onClose: () => dispatch(setModel(null)),
          })
        );
      } finally {
        dispatch(stopLoading());
      }
    };
    getFormDynamicValues();
  }, []);

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "job",
      type: "select",
      label: "Poste",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "structureRep",
      type: "abstractSelect",
      label: "Structure Représentative",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      handleChange: (selected, formik) => {
        autoFillSiteOptions(null, formik, selected?.value);
      },
      required: true,
    },
    {
      name: "structure",
      type: "abstractSelect",
      label: "Site",
      required: true,
      grid: {
        xs: 12,
        md: 6,
      },
      trigger: {
        name: "job",
        triggerFunc: (val: any, formik) => autoFillSiteOptions(val, formik),
      },
    },
    {
      name: "civility",
      type: "select",
      label: "Civilité",
      options: [
        { label: "Mr.", value: "Mr." },
        { label: "Mme.", value: "Mme." },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "Nom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "landLine",
      type: "tel",
      label: "Téléphone ligne fixe directe",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "phone",
      type: "tel",
      label: "Téléphone portable",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
  ]);

  return (
    <PageWrapper>
      <h2>Création d’un APA commercial</h2>
      <FormGenerator
        fields={formFields}
        submitButtonText="Sauvegarder"
        widthReturn
        onSubmit={handleFormSubmit}
      />
    </PageWrapper>
  );
}
