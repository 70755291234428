import { Link } from "react-router-dom";
import Icon from "./Icon";

interface InfoBoxProps {
  stateData: StateData;
}
interface StateData {
  title: string;
  list?: { label: string; value: string }[];
  boxes?: { label: string; value: string }[];
}
const InfoBoxAdmin = ({ stateData }: InfoBoxProps) => {
  return (
    <div className="info-box">
      <h3 className="stat-label">{stateData.title}</h3>
      {stateData.list && stateData.list.length > 0 && (
        <ul className="list">
          {stateData.list.map((item, i) => (
            <li key={i}>
              <span className="key">{item.label} : </span>
              <span className="value">{item.value}</span>
            </li>
          ))}
        </ul>
      )}

      {stateData.boxes && stateData.boxes.length > 0 && (
        <>
          <ul className="boxes">
            {stateData.boxes.map((item, i) => (
              <li key={i}>
                <span className="value">{item.value}</span>
                <span className="key">{item.label}</span>
              </li>
            ))}
          </ul>
          <Link className="link" to="/activite-commerciale">
            <Icon icon="arrowRight" size={12} />
            Plus de détails
          </Link>
        </>
      )}
    </div>
  );
};

export default InfoBoxAdmin;
