import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import CFCApi from "../apis/";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import Button from "../components/Button";
import extractIdsFromStrings from "../helpers/extractIdFromStrings";
import moment from "moment";

export default function AttribuerDossier() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [searchKey, setSearchKey] = useState<null | string>(null);

  let {
    state: { folderData },
  } = useLocation();

  const handleAssignFolder = async (user: any) => {
    try {
      dispatch(startLoading());
      const StructureEditResponse = await CFCApi.patch(
        `/contacts/${extractIdsFromStrings(
          folderData["@id"]
        )}/share?action=assigne`,
        {
          users: [user["@id"]],
        }
      );
      
      if (StructureEditResponse) {
        dispatch(
          setModel({
            type: "success",
            content: `Le dossier a été attribué à ${user.firstName} ${user.lastName} avec succès`,
            onClose: () =>{ 
              navigate("/settings")
              dispatch(setModel(null))
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSearch = (value: string | null) => {
    if (!value) {
      return;
    }
    setSearchKey(value);
  };

  return (
    <PageWrapper>
      <div className="affectation-container">
        <h1>Modification d’affectation de contact/dossier</h1>

        <div className="folders-listing-item">
          <div className="folders-listing-item-inner">
            <div className="folders-listing-item-content">
              <p>
                <span>{folderData["@type"]} : Crée le </span>
                {moment(folderData.createdAt).format("DD/MM/YYYY")}
              </p>
              <p>
                <span>Par </span>
                <b>
                  {folderData.firstName} {folderData.lastName}
                </b>
              </p>
            </div>
          </div>
        </div>

        <div className="attribuer-dossier-search">
          <Search
            handleSearch={handleSearch}
            placeholder="Rechercher un user par nom"
          />
        </div>

        <div className="attribuer-dossier-results">
          {users && users.length > 0 && (
            <div className="folders-user-listing">
              {users.map((user: any) => (
                <div key={user.id} className="folder-user-item">
                  <h3 className="folder-user-title">
                    {user.firstName} {user.lastName}
                  </h3>
                  <div className="folder-user-actions">
                    <Button
                      onPress={() => handleAssignFolder(user)}
                      text="Attribuer le dossier"
                      size="sm"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {users && users.length === 0 && searchKey && (
            <div className="text-center">Aucun résultat trouvé.</div>
          )}

          {searchKey && (
            <Pagination
              apiData={`/users?name=${searchKey}`}
              getCurrentItems={(current) => {
                if (!areObjectsEqual(current, users)) {
                  setUsers(current);
                }
              }}
            />
          )}
        </div>
      </div>
    </PageWrapper>
  );
}
