import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import CFCApiHydra from "../apis/CFCApiHydra";
import { useDispatch } from "react-redux";
import { setModel } from "../state/slices/appSlice";
import Loading from "./Loading";

interface PaginatedItemsProps {
  apiData: string;
  getCurrentItems: (data: any) => void;
  getfullResponse?: boolean;
  responseItemsName?: string;
  getTotalItems?: (data: any) => void;
}

function Pagination({
  apiData,
  getCurrentItems,
  getfullResponse,
  responseItemsName = "hydra:member",
  getTotalItems,
}: PaginatedItemsProps) {
  const dispatch = useDispatch();

  const [, setCurrentItems] = useState([]);
  const [paginationData, setPaginationData] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (ppage: number) => {
    try {
      setIsLoading(true);
      const response = await CFCApiHydra.get(
        apiData.includes("?")
          ? `${apiData}&page=${ppage}`
          : `${apiData}?page=${ppage}`
      );
      setPaginationData({
        ...response["hydra:view"],
        "hydra:totalItems": response["hydra:totalItems"],
      });

      setCurrentItems(getfullResponse ? response : response[responseItemsName]);
      getCurrentItems(getfullResponse ? response : response[responseItemsName]);

      if (getTotalItems) {
        getTotalItems(response["hydra:totalItems"]);
      }
    } catch (err: any) {
      setModel({
        type: "error",
        content: err.message,
        onClose: () => dispatch(setModel(null)),
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(1);
    setPage(0);
  }, [apiData]);

  const handlePageClick = ({ selected }: { selected: number }) => {
    fetchData(selected + 1);
    setPage(selected);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="pagination-wrapper">
      {paginationData &&
        Math.ceil(paginationData["hydra:totalItems"] / 5) > 1 && (
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(paginationData["hydra:totalItems"] / 5)}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={page}
          />
        )}
    </div>
  );
}

export default Pagination;
