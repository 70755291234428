import PageWrapper from "../components/Layout/PageWrapper";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Button from "../components/Button";

export default function GestionBOStructures() {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12} justifyContent="center">
          <div className="setting-actions">
            <h3>Création des structures</h3>
            <Link className="btn" to="/create-bo-strucutres">
              création de structure
            </Link>
            <Link className="btn" to="/list-structures">
              liste des structures
            </Link>
            <Button
            text="RETOUR"
              onPress={() => navigate(-1)}
           />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
