import React from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";
import DatePicker, { registerLocale } from "react-datepicker"; // Correct package name
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker styles
import Tooltip from "../Tooltip";

registerLocale("fr", fr); // register it with the name you want

interface DateFieldProps {
  field: Field;
  formik: any;
}

const HourField: React.FC<DateFieldProps> = ({ field, formik }) => {
  const fieldValue = formik.values[field.name] || ""; // Initialize with an empty string if undefined
  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>
      <div className="input-wrapper input-wrapper-with-icon">
        <span className="icon">
          <Icon icon="time" size={20} />
        </span>
        <DatePicker
          locale="fr"
          className="form-control form-control-date"
          id={field.name}
          name={field.name}
          selected={fieldValue}
          onChange={(date: any) => {
            formik.setFieldValue(field.name, date);
          }}
          showTimeSelect
          showTimeSelectOnly
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          timeCaption="Heure"
        />
        {field.isEditable ? (
          <span className="editable-icon">
            <Icon icon="note-pencil" size={15} />
          </span>
        ) : null}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default HourField;
