import { useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Autocomplete from "../components/Form/Autocomplete";
import Organigram from "../components/Organigram";
import { useDispatch } from "react-redux";
import { setModel } from "../state/slices/appSlice";
import CFCApiHydra from "../apis/CFCApiHydra";
import FilterAPA from "./FilterAPA";

export default function APA() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [organigramData, setOrganigramData] = useState([]);
  const [suggestion, setSuggestion] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showOrganigram, setShowOrganigram] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const toggleFilter = () => {
    setIsOpenFilter((prevState) => !prevState);
  };

  const closeFilter = () => {
    setIsOpenFilter(false);
  };

  const fetchOrganigramData = async (id: number, type: string) => {
    try {
      setLoading(true);
      const response = await CFCApiHydra.get(
        `/apas/${id}/organigram?for=${type}`
      );
      setOrganigramData({
        contactData: { id },
        navigateToParam: type,
        ...response,
      });
    } catch (err: any) {
      dispatch(
        setModel({
          type: "error",
          content: err.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOrganigramSuggestionClick = (picked: any) => {
    fetchOrganigramData(picked.id, picked.type);
    setSuggestion(picked);
    setShowOrganigram(true);
  };

  return (
    <PageWrapper>
      <h1 className="text-center">APA</h1>

      <div className="apa-filter-btns-wrapper">
        <div className="apa-btns-wrapper">
          <Button
            text="RECHERCHER UN APA PAR ENTONNOIR"
            icon="Search"
            block
            onPress={toggleFilter}
            // onPress={() => navigate('/apa/filter')}
          />

          <Button
            text="LISTE DE MES APA"
            block
            to={() => navigate("/apa/list-apa")}
          />
          <Button
            text="CRÉER UN APA"
            icon="plus"
            block
            to={() => navigate("/apa/add-apa")}
          />
          <Button
            text="LISTE DE MES STRUCTURES "
            block
            to={() => navigate("/list-structures")}
          />
          <Button
            text="CRÉER SITE/AGENCE/GROUPE "
            icon="plus"
            block
            to={() => navigate("/apa/add-structure")}
          />
        </div>
        {isOpenFilter && <FilterAPA handleClose={closeFilter} />}
      </div>

      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="apa-search">
            <h2>Rechercher un APA par nom : </h2>
            <Autocomplete apiUrl="/apas" navigateTo="/apa/edit-fiche-apa" />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          display={{
            xs: "block",
            md: "none",
          }}
        >
          <hr className="apa-sep" />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="apa-search">
            <h2>Rechercher un organigramme : </h2>
            <Autocomplete
              apiUrl="/organigrams"
              navigateTo="/apa/edit-fiche-apa"
              onSuggestionClick={handleOrganigramSuggestionClick}
              formatSuggestion={(suggestion) => ({
                display: `${suggestion["label"]}`,
                id: suggestion["value"],
                type: suggestion["type"],
              })}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          {showOrganigram && (
            <div>
              {loading ? (
                <div className="organigram-loader">Chargement ...</div>
              ) : (
                <div className="apa-orgranigram">
                  <Organigram data={organigramData} showDetails={true} />
                  <div className="apa-btns-wrapper apa-btn-ogra">
                    <Button
                      text="AFFICHER L’ORGANIGRAMME "
                      icon="eye"
                      block
                      to={() =>
                        navigate(
                          `/apa/organigram-details?id=${suggestion.id}&type=${suggestion.type}`,
                          {}
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
