import { useState } from 'react';
import FormGenerator from '../components/Form';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button';
import Icon from '../components/Icon';
import Pagination from '../components/Pagination';

import areObjectsEqual from '../helpers/checkObjectsEqual';
import extractIdsFromStrings from '../helpers/extractIdFromStrings';

interface IStep {
  title: string;
  placeholder: string;
  options?: any;
}

interface FilterAPAProps {
  handleClose?: () => void;
}

export default function FilterAPA({ handleClose }: FilterAPAProps) {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStructureId, setCurrentStructureId] = useState(0);

  const [resultsId, setResultsId] = useState(0);

  const [searchKey, setSearchKey] = useState<null | string>(null);

  const [searchEndpoint, setSearchEndpoint] = useState<string>(
    `/structures/subStructures?id=${0}`
  );

  const [stepData, setStepData] = useState<IStep>({
    title: '',
    placeholder: '',
    options: [],
  });

  const [stepIterator, setStepIterator] = useState([
    {
      stepStructureId: 0,
    },
  ]);

  const [filterFormFields, setFilterFormFields] = useState<any[]>([
    {
      name: `filter_results${currentStep}`,
      type: 'radio',
      label: '',
      options: [],
      grid: {
        xs: 12,
        md: 12,
      },
    },
  ]);

  const [stepError, setStepError] = useState('');

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const [, setApasEndpoint] = useState<string>(`/structures/${resultsId}/apas`);

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setIsNextDisabled(false);

      // Remove the last step from stepIterator
      setStepIterator((prevIterator) => {
        const updatedIterator = [...prevIterator];
        updatedIterator.pop(); // Remove the last step
        return updatedIterator;
      });

      // Get the previous step's structure ID from stepIterator
      const previousStep = stepIterator[currentStep - 1];
      const previousId = previousStep ? previousStep.stepStructureId : 0;

      // Update the search endpoint and currentStructureId
      setSearchEndpoint(`/structures/subStructures?id=${previousId}`);
      setCurrentStructureId(previousId);
      setResultsId(previousId);

      setStepError('');
    }
  };

  const handleShowResults = (formValues: any) => {
    const dynamicFieldName = `filter_results${currentStep}`;

    if (formValues[dynamicFieldName] !== undefined) {
      const id = extractIdsFromStrings(formValues[dynamicFieldName].toString());
      const redirectUrl = `/structures/${id}/apas?allApas=true`;
      setApasEndpoint(`/structures/${id}/apas?allApas=true`);
      navigate('/apa/results', { state: redirectUrl });
    } else if (currentStructureId !== 0) {
      const redirectUrl = `/structures/${currentStructureId}/apas?allApas=true`;
      setApasEndpoint(`/structures/${currentStructureId}/apas?allApas=true`);
      navigate('/apa/results', { state: redirectUrl });
    } else {
      setStepError('Veuillez sélectionner une option.');
    }
  };

  const handleNext = async (values: any) => {
    setIsNextDisabled(true);
    const dynamicFieldName = `filter_results${currentStep}`;
    if (values[dynamicFieldName] !== undefined) {
      setCurrentStep(currentStep + 1);
      const id = extractIdsFromStrings(values[dynamicFieldName].toString());
      setSearchEndpoint(`/structures/subStructures?id=${id}`);
      setCurrentStructureId(id);
      setResultsId(id);

      // Add the new step to the stepIterator
      setStepIterator((prevIterator) => [
        ...prevIterator,
        {
          stepStructureId: id,
        },
      ]);
      setStepError('');
    } else {
      setStepError('Veuillez sélectionner une option.');
    }
  };

  const handleSearch = async (e: any) => {
    e.preventDefault();
    if (searchKey) {
      if (searchKey !== '') {
        setSearchEndpoint(
          `/structures/${currentStructureId}/subStructures?name=${searchKey}`
        );
      }
    }
  };

  const handleRadioChange = async (values: any) => {
    const dynamicFieldName = `filter_results${currentStep}`;
    if (values[dynamicFieldName] !== undefined) {
      const selectedId = extractIdsFromStrings(
        values[dynamicFieldName].toString()
      );
      const foundObject = stepData.options.find(
        (option: any) => option.id === selectedId
      );
      const isLastValue = foundObject ? foundObject.isLast : false;
      setIsNextDisabled(isLastValue);
    } else {
      setStepError('Veuillez sélectionner une option.');
    }
  };

  return (

      <div className="steps-filter">
        <div className="steps-filter-wrapper">
          <div className="steps-filter-header">
            <div className="steps-filter-header-btns">
              {currentStep === 0 ? (
                <span className="step-btn" onClick={handleClose}>
                  <Icon icon="xmark" size={14} />
                </span>
              ) : (
                <span className="step-btn" onClick={handleBack}>
                  <Icon icon="arrow-left" size={14} />
                </span>
              )}
            </div>
            <h2 className="steps-filter-header-title">{stepData.title}</h2>
          </div>

          <div>
            <form action="#" onSubmit={handleSearch}>
              <div className="form-group form-group-search">
                <div className="input-wrapper input-wrapper-with-icon">
                  <span className="icon">
                    <Icon icon="Search" size={20} />
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={stepData.placeholder}
                    onKeyUp={(e: any) => setSearchKey(e.target.value)}
                  />
                </div>
                <div className="action">
                  <Button text="search" submit />
                </div>
              </div>
            </form>
          </div>

          <div className="search-wrapper-results">
            {stepError && (
              <div className="form-group-error text-center">{stepError}</div>
            )}

            <div className="step-form">
              <FormGenerator
                fields={filterFormFields}
                nextButtonDisabled={isNextDisabled}
                onShowResults={handleShowResults}
                onShowNext={handleNext}
                onChange={handleRadioChange}
              />
            </div>

            <Pagination
              apiData={searchEndpoint}
              getfullResponse
              getCurrentItems={(current) => {
                setStepData({
                  title: current.substructures[0]?.typeName,
                  placeholder: `Recherche par ${
                    current.substructures[0]?.typeName || ''
                  }`,
                  options: current.substructures.map((substructure: any) => ({
                    name: substructure.name,
                    id: substructure.isRootStructure
                      ? substructure['@id']
                      : substructure['id'],
                    isLast: substructure.isLast ? substructure.isLast : false,
                  })),
                });
                if (!areObjectsEqual(current, filterFormFields)) {
                  setFilterFormFields([
                    {
                      name: `filter_results${currentStep}`,
                      type: 'radio',
                      label: '',
                      options: current.substructures.map(
                        (substructure: any) => ({
                          label: substructure.name,
                          value: substructure.isRootStructure
                            ? substructure['@id']
                            : substructure['id'],
                        })
                      ),
                      grid: {
                        xs: 12,
                        md: 12,
                      },
                    },
                  ]);
                }
              }}
            />
          </div>
        </div>
      </div>

  );
}
