import Cookies from "js-cookie";
import decode from "jwt-decode";
let isAdmin = false;
const token = Cookies.get("token");
if (token) {
  const user: any = decode(token);
  isAdmin = user?.roles?.includes("ROLE_ADMIN");
}

const route_links = {
  espace: {
    name: isAdmin ? "Suivi Activité" : "Mon espace commercial",
    url: isAdmin ? "/activite-commerciale" : "/espace-commercial",
  },
};
export default route_links;
