import React from 'react';

interface HintFieldProps {
  label: string;
}

const HintField: React.FC<HintFieldProps> = ({ label }) => {
  return (
    <div className="form-group">
      <div className="form-group-hint">{label}</div>
    </div>
  );
};

export default HintField;
