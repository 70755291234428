import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator from "../components/Form";
import useContact from "../hooks/useContact";
import useTrigger from "../hooks/useTrigger";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CFCApi from "../apis/";

export default function AddContact() {
  const location = useLocation();
  const isLoading = useSelector((state: any) => state.app.loading);

  const contactValues = location?.state?.contactValues.formValues;
  const coEmprunteurValues = location?.state?.coEmprunteurValues;

  const { contactFormFields, updateCoEmprunteur } = useContact(
    contactValues,
    coEmprunteurValues
  );
  const { triggerModel, triggerError, triggerLoading } = useTrigger();
  const navigate = useNavigate();

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    try {
      triggerLoading(true);

      const response = await CFCApi.post("/contacts", values);

      if (!response) {
        throw new Error(
          "Une erreur inattendue s'est produite lors de la création du contact."
        );
      }

      if (coEmprunteurValues) {
        updateCoEmprunteur(response["@id"], response, coEmprunteurValues);
        return;
      }

      if (response) {
        triggerModel("success", "Contact créé avec succès", () => {
          navigate("/");
        });
      }
    } catch (error: any) {
      triggerError(error);
    } finally {
      triggerLoading(false);
    }
  };

  return (
    <PageWrapper>
      <h1>Ajout d’un contact </h1>
      {!isLoading && (
        <FormGenerator
          fields={contactFormFields}
          initialValues={contactValues}
          submitButtonText="Sauvegarder"
          widthReturn
          onSubmit={handleFormSubmit}
        />
      )}
    </PageWrapper>
  );
}
