import React, { useState } from "react";
import Button from "./Button";
import Icon from "./Icon";

const StatsFilterForm = ({
  DAOptions,
  RSOptions,
  AgencesOptions,
  onSubmit,
  onResetForm,
}: any) => {
  // TODO: Make the parent filters dynamic (from the API)
  const [DAValue, setDAValue] = useState("");
  const [RSValue, setRSValue] = useState("");
  const [AgenceValue, setAgenceValue] = useState("");

  const [formError, setFormError] = useState("");

  const handleDAChange = (value: any) => {
    setDAValue(value);
    setFormError("");
    if (RSValue !== "" && RSValue !== value) {
      setRSValue("");
    }
    if (AgenceValue !== "" && AgenceValue !== value) {
      setAgenceValue("");
    }
  };

  const handleRSChange = (value: any) => {
    setRSValue(value);
    setFormError("");
    if (DAValue !== "" && DAValue !== value) {
      setDAValue("");
    }
    if (AgenceValue !== "" && AgenceValue !== value) {
      setAgenceValue("");
    }
  };

  const handleAgenceChange = (value: any) => {
    setAgenceValue(value);
    setFormError("");
    if (RSValue !== "" && RSValue !== value) {
      setRSValue("");
    }
    if (DAValue !== "" && DAValue !== value) {
      setDAValue("");
    }
  };

  const resetForm = () => {
    setDAValue("");
    setRSValue("");
    setAgenceValue("");
    onResetForm();
  };

  const handleSubmit = () => {
    if (!DAValue && !RSValue && !AgenceValue) {
      setFormError("Veuillez sélectionner une option.");
    } else {
      setFormError("");
      onSubmit({ DAValue, RSValue, AgenceValue });
    }
  };

  const [expandedItemIndex, setExpandedItemIndex] = useState<number | null>(0);

  const handleArrowClick = (event: any, index: number) => {
    event.preventDefault();
    setExpandedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <button className="stats-filter-reset" onClick={resetForm}>
        Réinitialiser
      </button>

      <h2 className="filter-title">Filtrer par :</h2>

      {DAOptions.length > 0 && (
        <div className="form-group">
          <h4
            className="form-group-title"
            onClick={(event) => handleArrowClick(event, 0)}
          >
            <span>Par DA</span>
            <span
              className={`dropdown-arrow ${
                expandedItemIndex === 0 ? "expanded" : ""
              }`}
            >
              <Icon icon="arrow-down" size={14} />
            </span>
          </h4>
          <div
            className={`form-sublist ${
              expandedItemIndex === 0 ? "expanded" : ""
            }`}
          >
            <div className="input-wrapper">
              {DAOptions.map((option: any) => (
                <div className="radio-wrapper" key={option.value}>
                  <input
                    id={`group1-${option.value}`}
                    type="radio"
                    name="group1"
                    value={option.value}
                    checked={DAValue === option.value}
                    onChange={() => handleDAChange(option.value)}
                  />
                  <label htmlFor={`group1-${option.value}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {RSOptions.length > 0 && (
        <div className="form-group">
          <h4
            className="form-group-title"
            onClick={(event) => handleArrowClick(event, 1)}
          >
            <span>Par RS</span>
            <span
              className={`dropdown-arrow ${
                expandedItemIndex === 1 ? "expanded" : ""
              }`}
            >
              <Icon icon="arrow-down" size={14} />
            </span>
          </h4>
          <div
            className={`form-sublist ${
              expandedItemIndex === 1 ? "expanded" : ""
            }`}
          >
            <div className="input-wrapper">
              {RSOptions.map((option: any) => (
                <div className="radio-wrapper" key={option.value}>
                  <input
                    id={`group2-${option.value}`}
                    type="radio"
                    name="group2"
                    value={option.value}
                    checked={RSValue === option.value}
                    onChange={() => handleRSChange(option.value)}
                  />
                  <label htmlFor={`group2-${option.value}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {AgencesOptions.length > 0 && (
        <div className="form-group">
          <h4
            className="form-group-title"
            onClick={(event) => handleArrowClick(event, 2)}
          >
            <span>Par agence</span>
            <span
              className={`dropdown-arrow ${
                expandedItemIndex === 2 ? "expanded" : ""
              }`}
            >
              <Icon icon="arrow-down" size={14} />
            </span>
          </h4>
          <div
            className={`form-sublist ${
              expandedItemIndex === 2 ? "expanded" : ""
            }`}
          >
            <div className="input-wrapper">
              {AgencesOptions.map((option: any) => (
                <div className="radio-wrapper" key={option.value}>
                  <input
                    id={`group3-${option.value}`}
                    type="radio"
                    name="group3"
                    value={option.value}
                    checked={AgenceValue === option.value}
                    onChange={() => handleAgenceChange(option.value)}
                  />
                  <label htmlFor={`group3-${option.value}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {formError && (
        <div className="form-group-error text-center">{formError}</div>
      )}

      <div className="stats-filter-btn">
        <Button text="Valider" onPress={handleSubmit} />
      </div>
    </div>
  );
};

export default StatsFilterForm;
