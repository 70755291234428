import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../components/Layout/PageWrapper";
import { Grid } from "@mui/material";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import { useDispatch } from "react-redux";
import Button from "../components/Button";
import CFCApiHydra from "../apis/CFCApiHydra";

export default function AddBOStructureType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { rootStructures },
  } = useLocation();

  const [innerRootStructures, setInnerRootStructures] = useState<
    { name: string }[]
  >(rootStructures || []);

  const [toAdd, setToAdd] = useState<{ name: string }[]>([]);
  const [toUpdate, setToUpdate] = useState<
    { "@id": string; name: string }[] | string[]
  >([]);

  useEffect(() => {
    const filtToAdd = innerRootStructures.filter((rs: any) => {
      delete rs["@type"];
      return !rs["@id"] && rs?.name?.trim() !== "";
    });
    const filtToUpdate: any = innerRootStructures.filter((rs: any) => {
      if (rs["@id"] /* && rs.isModified */) {
        delete rs["@type"];
        delete rs.isModified;
        return true;
      }
      return false;
    });

    setToAdd(filtToAdd);
    setToUpdate(filtToUpdate);
  }, [innerRootStructures]);

  const handleAdd = () => {
    if (
      innerRootStructures[innerRootStructures.length - 1]?.name?.trim() === ""
    ) {
      dispatch(
        setModel({
          type: "error",
          content: "veuillez remplir le champ avant d'en ajouter un nouveau",
          onClose: () => dispatch(setModel(null)),
        })
      );
      return;
    }
    setInnerRootStructures([...innerRootStructures, { name: "" }]);
  };

  const handleInputChange = (e: any, i: number) => {
    let innerRootStructuresUpdate: {
      "@id"?: string;
      name: string;
      isModified?: boolean;
    }[] = [...innerRootStructures];
    innerRootStructuresUpdate[i].name = e.target.value;
    setInnerRootStructures(innerRootStructuresUpdate);
  };

  const handleSubmit = async () => {
    const reqs = [];

    if (rootStructures.length > 0) {
      reqs.push(
        CFCApiHydra.put("/structure_root_types", {
          types: [...toUpdate, ...toAdd],
        })
      );
    } else {
      reqs.push(CFCApiHydra.post("/structure_root_types", { types: toAdd }));
    }

    if (reqs.length <= 0) {
      return;
    }
    dispatch(startLoading());
    Promise.all(reqs)
      .then((resps) => {
        if (resps) {
          dispatch(
            setModel({
              type: "success",
              content: "Les types a été créé avec succès",
              onClose: () => {
                navigate("/create-bo-strucutres");
                dispatch(setModel(null));
              },
            })
          );
        } else {
          dispatch(
            setModel({
              type: "error",
              content: "erreur lors de l'obtention de la structure racine",
              onClose: () => dispatch(setModel(null)),
            })
          );
        }
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12} justifyContent="center">
          <div className="setting-actions">
            <h3>Créer un type de structure</h3>
            <div className="form-group">
              {innerRootStructures.map((rs, i) => {
                return (
                  <div key={i} className="input-wrapper">
                    <input
                      className="form-control"
                      type="text"
                      name="category"
                      value={rs.name}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                );
              })}

              <Button text="Ajouter" onPress={handleAdd} />
            </div>
            <Button text="sauvegarder" onPress={handleSubmit} />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
