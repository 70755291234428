import { useState } from "react";
import Button from "../components/Button";
import PageWrapper from "../components/Layout/PageWrapper";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";

export default function Nomenclatures() {
  const navigate = useNavigate();

  const [folderTypes, setFolderTypes] = useState<any>([]);
  const [rdvTypes, setRdvTypes] = useState<any>([]);

  const handleAddNew = (type: any) => {
    navigate("/nomenclatures/add", { state: { itemData: { type: type } } });
  };

  return (
    <PageWrapper>
      <div className="nomenclatures">
        <h1>Paramétrage type de RDV/ type de dossiers</h1>

        <div className="type-wrapper">
          <h2>Type de dossier</h2>

          <ul className="nomenclatures-types-list">
            {folderTypes.map((item: any) => (
              <li key={item["@id"]}>
                {item.parent ? (
                  <Link
                    to="/nomenclatures/edit"
                    state={{
                      itemData: { id: item.parent["@id"], type: "folder" },
                    }}
                  >
                    {`${item.parent.name}: ${item.name}`}
                  </Link>
                ) : (
                  <span>-- : {item.name}</span>
                )}
              </li>
            ))}
          </ul>

          <Pagination
            apiData={`/types?hasParent=false`}
            getCurrentItems={(current) => {
              if (!areObjectsEqual(current, folderTypes)) {
                setFolderTypes(current);
              }
            }}
          />

          <div className="button-wrapper">
            <Button
              text="Ajouter"
              icon="plus"
              onPress={() => handleAddNew("folder")}
              size="sm"
            />
          </div>
        </div>

        <div className="type-wrapper">
          <h2>Type de rendez-vous</h2>

          <ul className="nomenclatures-types-list">
            {rdvTypes.map((item: any) => (
              <li key={item["@id"]}>
                {item.parentType ? (
                  <Link
                    to="/nomenclatures/edit"
                    state={{
                      itemData: { id: item.parentType["@id"], type: "rdv" },
                    }}
                  >
                    {`${item.parentType.name}: ${item.name}`}
                  </Link>
                ) : (
                  <span>-- : {item.name}</span>
                )}
              </li>
            ))}
          </ul>

          <Pagination
            apiData={`/appointment_types?hasParent=false&itemsPerPage=5`}
            getCurrentItems={(current) => {
              if (!areObjectsEqual(current, rdvTypes)) {
                setRdvTypes(current);
              }
            }}
          />

          <div className="button-wrapper">
            <Button
              text="Ajouter"
              icon="plus"
              onPress={() => handleAddNew("rdv")}
              size="sm"
            />
          </div>
        </div>

        <div className="button-wrapper back-button">
          <Button text="RETOUR" to={() => navigate("/settings")} size="sm" />
        </div>
      </div>
    </PageWrapper>
  );
}
