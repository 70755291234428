import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OperationNames, Field } from "../components/Form";
import useTrigger from "../hooks/useTrigger";
import CFCApiHydra from "../apis/CFCApiHydra";
import CFCApi from "../apis/";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import route_links from "../shared/route_links";

const useDossier = (
  contactData: any,
  coEmprunteurValues: any,
  setPopupAtachment: any
) => {
  const { triggerModel, triggerError, triggerLoading } = useTrigger();

  const navigate = useNavigate();

  const [savedUser, setSavedUser] = useState<any>(null);
  const [showForm, setShowForm] = useState<boolean>(false);

  const autoFillSite = (val: string) => {
    if (formFields[2]?.options) {
      const field: any = formFields[2].options.find(
        (opt: any) => opt.value === val
      );
      return {
        label: field?.structure?.name || "",
        value: field?.structure["@id"] || null,
      };
    } else {
      return { label: null, value: null };
    }
  };

  const handleReset = async (id: any) => {
    triggerLoading(true);
    try {
      const resetResponse = await CFCApiHydra.patch(
        `/folders/${extractIdFromStrings(id)}/reset`
      );
      if (resetResponse) {
        navigate(route_links.espace.url);
      } else {
        triggerModel(
          "error",
          "Une erreur inattendue s'est produite lors de la réinitialisation du dossier."
        );
      }
    } catch (error: any) {
      triggerError(error);
    } finally {
      triggerLoading(false);
    }
  };

  useEffect(() => {
    const getFormDynamicValues = async () => {
      triggerLoading(true);
      try {
        Promise.all([
          CFCApiHydra.get("/types?pagination=false&hasParent=false"),
          CFCApiHydra.get(
            "/apas?type=commercial&withRep=true&pagination=false"
          ),
          CFCApiHydra.get(`/contacts/${extractIdFromStrings(contactData.id)}`),
        ])
          .then(([typesResponse, APAResponse, ContactResponse]) => {
            if (typesResponse["hydra:member"]) {
              let formFieldsUpdated = formFields;
              formFieldsUpdated[0].options = typesResponse["hydra:member"].map(
                (opt: any) => ({
                  label: opt.name,
                  value: opt["@id"],
                })
              );
              setFormFields(formFieldsUpdated);
            } else {
              triggerModel(
                "error",
                "Une erreur inattendue s'est produite lors de la récupération du type de contact."
              );
            }
            if (APAResponse["hydra:member"]) {
              let formFieldsUpdated = formFields;
              formFieldsUpdated[2].options = APAResponse["hydra:member"].map(
                (opt: any) => ({
                  label: `${opt.firstName} ${opt.lastName}`,
                  value: opt["@id"],
                  structure: opt["structure"],
                })
              );
              setFormFields(formFieldsUpdated);
            } else {
              triggerModel(
                "error",
                "Une erreur inattendue s'est produite lors de la récupération de l'APA."
              );
            }

            if (ContactResponse) {
              setSavedUser(ContactResponse);
              const formFieldsUpdated = formFields;
              formFieldsUpdated[1].action.handler = () =>
                handleReset(ContactResponse.folder["@id"]);

              formFieldsUpdated[17].url = `/edit-co-emprunteur?contactId=${extractIdFromStrings(
                ContactResponse["@id"]
              )}`;

              for (let key in ContactResponse) {
                switch (key) {
                  case "type":
                    formFieldsUpdated[0].defaultValue =
                      contactData[key] || ContactResponse[key]["@id"];
                    break;
                  case "status":
                    formFieldsUpdated[1].defaultValue =
                      contactData[key] || ContactResponse[key];
                    switch (ContactResponse[key]) {
                      case "making_contact":
                      case "unreachable":
                        formFieldsUpdated[1].options = [
                          {
                            label: "Prise de contact",
                            value: "making_contact",
                          },
                          { label: "Injoignable", value: "unreachable" },
                          { label: "Faisabilité", value: "feasibility" },
                        ];
                        break;
                      case "feasibility":
                        formFieldsUpdated[1].options = [
                          { label: "Faisabilité", value: "feasibility" },
                          { label: "étude à domicile", value: "home_study" },
                          { label: "étude à distance", value: "remote_study" },
                          {
                            label: "Sans suite client",
                            value: "without_customer_followup",
                          },
                          {
                            label: "Sans suite hors normes",
                            value: "without_extraordinary_followup",
                          },
                        ];
                        break;
                      case "remote_study":
                        formFieldsUpdated[1].options = [
                          { label: "étude à distance", value: "remote_study" },
                          {
                            label: "Sans suite client",
                            value: "without_customer_followup",
                          },
                          { label: "Envoi Bo", value: "send_bo" },
                        ];
                        break;
                      case "home_study":
                        formFieldsUpdated[1].options = [
                          { label: "étude à domicile", value: "home_study" },
                          {
                            label: "Sans suite client",
                            value: "without_customer_followup",
                          },
                          { label: "Envoi Bo", value: "send_bo" },
                        ];
                        break;
                      case "send_bo":
                        formFieldsUpdated[1].options = [
                          { label: "Envoi Bo", value: "send_bo" },
                          {
                            label: "Sans suite client",
                            value: "without_customer_followup",
                          },
                          { label: "Refus bo", value: "rejected_bo" },
                          { label: "Refus banque", value: "rejected_bank" },
                          { label: "Accord", value: "agreement" },
                        ];
                        break;
                      case "agreement":
                        formFieldsUpdated[1].options = [
                          { label: "Accord", value: "agreement" },
                          {
                            label: "Sans suite client",
                            value: "without_customer_followup",
                          },
                          { label: "Financé", value: "financed" },
                          { label: "Refus banque", value: "rejected_bank" },
                        ];
                        break;
                      case "Accord":
                        formFieldsUpdated[1].options = [
                          { label: "Accord", value: "agreement" },
                        ];
                        break;
                      case "without_customer_followup":
                        formFieldsUpdated[1].options = [
                          {
                            label: "Sans suite client",
                            value: "without_customer_followup",
                          },
                          {
                            label: "Prise de contact",
                            value: "making_contact",
                          },
                          { label: "Faisabilité", value: "feasibility" },
                          { label: "Injoignable", value: "unreachable" },
                        ];
                        break;
                      case "financed":
                        formFieldsUpdated[1].options = [
                          { label: "Financé", value: "financed" },
                          {
                            label: "Prise de contact",
                            value: "making_contact",
                          },
                          { label: "Faisabilité", value: "feasibility" },
                          { label: "Injoignable", value: "unreachable" },
                        ];
                        break;
                      case "rejected_bank":
                        formFieldsUpdated[1].options = [
                          { label: "Refus banque", value: "rejected_bank" },
                          {
                            label: "Prise de contact",
                            value: "making_contact",
                          },
                          { label: "Faisabilité", value: "feasibility" },
                          { label: "Injoignable", value: "unreachable" },
                        ];
                        break;
                      case "without_extraordinary_followup":
                        formFieldsUpdated[1].options = [
                          {
                            label: "Sans suite hors normes",
                            value: "without_extraordinary_followup",
                          },
                          {
                            label: "Prise de contact",
                            value: "making_contact",
                          },
                          { label: "Faisabilité", value: "feasibility" },
                          { label: "Injoignable", value: "unreachable" },
                        ];
                        break;
                      case "rejected_bo":
                        formFieldsUpdated[1].options = [
                          { label: "Refus bo", value: "rejected_bo" },
                          {
                            label: "Prise de contact",
                            value: "making_contact",
                          },
                          { label: "Faisabilité", value: "feasibility" },
                          { label: "Injoignable", value: "unreachable" },
                        ];
                        break;
                      default:
                        formFieldsUpdated[1].options = [];
                        break;
                    }
                    break;
                  case "apa":
                    formFieldsUpdated[2].defaultValue =
                      contactData[key] || ContactResponse[key]["@id"];
                    break;
                  case "site":
                    formFieldsUpdated[3].defaultValue =
                      contactData[key] || ContactResponse[key]["@id"];
                    break;
                  case "createdAt":
                    formFieldsUpdated[4].defaultValue = new Date(
                      contactData[key] || ContactResponse[key]
                    );
                    break;
                  case "civility":
                    formFieldsUpdated[5].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "lastName":
                    formFieldsUpdated[6].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "partnerShipName":
                    formFieldsUpdated[7].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "firstName":
                    formFieldsUpdated[8].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "birthDate":
                    formFieldsUpdated[9].defaultValue = new Date(
                      contactData[key] || ContactResponse[key]
                    );
                    break;
                  case "maritalStatus":
                    formFieldsUpdated[10].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "phone":
                    formFieldsUpdated[11].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "email":
                    formFieldsUpdated[12].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "landLine":
                    formFieldsUpdated[13].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "address":
                    formFieldsUpdated[14].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "zipCode":
                    formFieldsUpdated[15].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "city":
                    formFieldsUpdated[16].defaultValue =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "coBorrowerFirstName":
                    formFieldsUpdated[17].defaultValue = `${ContactResponse[key]} ${ContactResponse["coBorrowerLastName"]}`;
                    formFieldsUpdated[17].url = `/edit-co-emprunteur?contactId=${extractIdFromStrings(
                      ContactResponse["@id"]
                    )}`;

                    if (coEmprunteurValues) {
                      const {
                        coBorrowerFirstName = "",
                        coBorrowerLastName = "",
                      } = coEmprunteurValues;
                      const name = `${coBorrowerFirstName} ${coBorrowerLastName}`;
                      formFieldsUpdated[17].defaultValue = name;
                    }

                    break;
                  case "attachments":
                    formFieldsUpdated[18].defaultValue = `${
                      (contactData[key] || ContactResponse[key] || []).length
                    } fichier${
                      (contactData[key] || ContactResponse[key] || []).length >
                      1
                        ? "s"
                        : ""
                    }`;
                    break;
                  case "comment":
                    formFieldsUpdated[19].defaultValue = null;
                    break;
                  case "comments":
                    formFieldsUpdated[19].details =
                      contactData[key] || ContactResponse[key];
                    break;
                  case "duration":
                    formFieldsUpdated[20].defaultValue =
                      contactData[key] || ContactResponse?.duration || "";
                    break;
                  case "folder":
                    formFieldsUpdated[21].defaultValue =
                      contactData.externalApaFolderNumber ||
                      ContactResponse?.folder?.externalApaFolderNumber ||
                      "";
                    formFieldsUpdated[22].defaultValue =
                      contactData.externalApaPackagerNumber ||
                      ContactResponse?.folder?.externalApaPackagerNumber ||
                      "";
                    formFieldsUpdated[23].defaultValue =
                      contactData.expirationEarnings ||
                      ContactResponse?.folder?.expirationEarnings ||
                      "";
                    break;
                  case "grossTurnover":
                    formFieldsUpdated[24].defaultValue =
                      contactData[key] || ContactResponse?.grossTurnover || "";
                    break;
                  case "retrocessionAmount":
                    formFieldsUpdated[25].defaultValue =
                      contactData[key] || ContactResponse[key] || "";
                    break;
                  case "netTurnover":
                    formFieldsUpdated[26].defaultValue =
                      contactData[key] || ContactResponse?.netTurnover || "";
                    break;
                  case "amount":
                    formFieldsUpdated[27].defaultValue =
                      contactData[key] || ContactResponse[key] || "";
                    break;
                  case "treasury":
                    formFieldsUpdated[28].defaultValue =
                      contactData[key] || ContactResponse[key] || "";
                    break;
                  default:
                }
              }
              setFormFields(formFieldsUpdated);
            } else {
              triggerModel(
                "error",
                "Une erreur inattendue s'est produite lors de la récupération des données de contact."
              );
            }
            setShowForm(true);
          })
          .catch((error: any) => {
            triggerError(error);
          })
          .finally(() => {
            triggerLoading(false);
          });
      } catch (error: any) {
        triggerError(error);
      }
    };

    getFormDynamicValues();
  }, []);

  const updateCoEmprunteur = async (
    contactDataId: string,
    contact?: any,
    values?: any
  ) => {
    if (!values) {
      return;
    }

    let modifiedId = contact
      ? contact["@id"].replace("/api", "")
      : contactDataId.replace("/api", "");

    triggerLoading(true);
    try {
      const response = await CFCApi.patch(`${modifiedId}/co-borrower`, values);

      if (response) {
        triggerModel(
          "success",
          contact
            ? "Contact et co-coemprunteur créés avec succès"
            : "mise à jour réussie"
        );
        return;
      }
      triggerModel(
        "error",
        "Une erreur inattendue s'est produite lors de la création du co-emprunteur."
      );
    } catch (error: any) {
      triggerError(error);
    } finally {
      triggerLoading(false);
    }
  };

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "type",
      type: "select",
      label: "type de contact",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "status",
      type: "select",
      label: "Statut",
      options: [
        { label: "Prise de contact", value: "making_contact" },
        { label: "Faisabilité", value: "feasibility" },
        { label: "étude à distance", value: "remote_study" },
        { label: "étude à domicile", value: "home_study" },
        { label: "Injoignable", value: "unreachable" },
        { label: "Sans suite client", value: "without_customer_followup" },
        {
          label: "Sans suite hors normes",
          value: "without_extraordinary_followup",
        },
        { label: "Refus bo", value: "rejected_bo" },
        { label: "Refus banque", value: "rejected_bank" },
        { label: "Envoi Bo", value: "send_bo" },
        { label: "Accord", value: "agreement" },
        { label: "Financé", value: "financed" },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      highlight: true,
      required: true,
      action: {
        name: "Réinitialiser",
      },
    },
    {
      name: "apa",
      type: "select",
      label: "APA Commercial",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "site",
      type: "abstract",
      label: "Site",
      isReadOnly: true,
      required: true,
      grid: {
        xs: 12,
        md: 6,
      },
      trigger: {
        name: "apa",
        triggerFunc: (val) => autoFillSite(val),
      },
      isEditable: true,
    },
    {
      name: "dateCreation",
      type: "date",
      label: "Date de Création",
      disableFuture: true,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isReadOnly: true,
    },
    {
      name: "civility",
      type: "select",
      label: "Civilité",
      options: [
        { label: "Mr.", value: "Mr." },
        { label: "Mme.", value: "Mme." },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "Nom du client",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "nomFilleClient",
      type: "text",
      label: "Nom de jeune fille du client",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "civility",
        values: ["Mme."],
      },
      isEditable: true,
      isHidden: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom du client",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "birthDate",
      type: "date",
      disableFuture: true,
      label: "Date de naissance",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "maritalStatus",
      type: "select",
      options: [
        { label: "Célibataire", value: "Célibataire" },
        { label: "Concubin(-e)", value: "Concubin(-e)" },
        { label: "Divorcé(-e)", value: "Divorcé(-e)" },
        { label: "Marié(-e)", value: "Marié(-e)" },
        { label: "Pacsé(-e)", value: "Pacsé(-e)" },
        { label: "Veuf(-ve)", value: "Veuf(-ve)" },
        { label: "Séparé(-e)", value: "Séparé(-e)" },
      ],
      label: "Situation matrimoniale",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "phone",
      type: "tel",
      label: "Téléphone portable",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "landLine",
      type: "tel",
      label: "Téléphone fixe",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "address",
      type: "text",
      label: "Adresse",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "zipCode",
      type: "text",
      label: "Code postal",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "city",
      type: "text",
      label: "Ville",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "co_borrower",
      type: "link_input",
      data: coEmprunteurValues,
      label: "Co emprunteur",
      grid: {
        xs: 12,
        md: 6,
      },
      url: `/edit-co-emprunteur`,
      parent: {
        depend: "maritalStatus",
        values: ["Marié(-e)", "Concubin(-e)"],
      },
    },

    {
      name: "attachment",
      type: "upload",
      label: "Pièces jointes",
      grid: {
        xs: 12,
        md: 6,
      },
      tooltip: "Carte d'identité, Passeport ou Permis",
      uploadAction: {
        handler: () => setPopupAtachment(true),
      },
    },
    {
      name: "commentaire",
      type: "textarea",
      label: "Commentaires",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isEditable: true,
    },
    {
      name: "duration",
      type: "number",
      label: "Durée en mois",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "externalApaFolderNumber",
      type: "text",
      label: "Numéro dossier externe-APA",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "externalApaPackagerNumber",
      type: "text",
      label: "Numéro dossier externe packageur",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "expirationEarnings",
      type: "text",
      label: "Gains sur échéance",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "grossTurnover",
      type: "text",
      label: "Chiffre d’affaires brut",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "retrocessionAmount",
      type: "text",
      label: "Rétrocession",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "netTurnover",
      type: "text",
      label: "Chiffre d’affaires Net",
      grid: {
        xs: 12,
        md: 6,
      },
      operation: {
        name: OperationNames.subtraction,
        targetField: "netTurnover",
        fields: ["grossTurnover", "retrocessionAmount"],
      },
      required: false,
      isEditable: false,
      isReadOnly: true,
      prefix: "€",
    },
    {
      name: "amount",
      type: "text",
      label: "Montant",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "treasury",
      type: "text",
      label: "Trésorerie",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
  ]);

  return { formFields, setFormFields, savedUser, updateCoEmprunteur, showForm };
};

export default useDossier;
