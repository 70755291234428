import PageWrapper from "../components/Layout/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import FormGenerator from "../components/Form";
import Grid from "../components/Layout/Grid";
import useContact from "../hooks/useContact";

export default function AddCoEmprunteur() {
  const location = useLocation();
  const navigate = useNavigate();

  const contactValues = location.state;
  const { coEmprunteurFields } = useContact(
    location?.state?.formValues,
    location?.state?.data,
  );

  const handleFormSubmit = (values: { [key: string]: string | string[] }) => {
    navigate("/add-contact", {
      state: { coEmprunteurValues: values, contactValues },
    });
  };

  return (
    <PageWrapper>
      <h1>Infos co-emprunteur </h1>
      <Grid container spacing={0}>
        <Grid>
          <FormGenerator
            fields={coEmprunteurFields}
            submitButtonText="Valider"
            widthReturn
            onSubmit={handleFormSubmit}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
