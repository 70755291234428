import { useState } from "react";
import Button from "../components/Button";
import PageWrapper from "../components/Layout/PageWrapper";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import CFCApi from "../apis";

export default function AccesCommerciaux() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [searchKey, setSearchKey] = useState<null | string>(null);

  const handleEdit = (user: any) => {
    navigate("/acces-commerciaux/edit", { state: user });
  };

  const handleResetPassword = async (user: any) => {
    try {
      dispatch(startLoading());
      const StructureEditResponse = await CFCApi.patch(
        `/users/reset-password?toAdmin=false`,
        {
          email: user.email,
        }
      );

      if (StructureEditResponse) {
        dispatch(
          setModel({
            type: "success",
            content: `Le mot de passe de ${user.firstName} ${user.lastName} a été réinitialisé`,
            onClose: () => dispatch(setModel(null)),
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while updating user",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleBlock = async (user: any) => {
    try {
      dispatch(startLoading());
      const StructureEditResponse = await CFCApi.patch(`/users/${user.id}`, {
        status: !user.status,
      });

      if (StructureEditResponse) {
        dispatch(
          setModel({
            type: "success",
            content: `Le compte de ${user.firstName} ${user.lastName} a été ${user.status ? "bloqué" : "débloqué"}`,
            onClose: () => dispatch(setModel(null)),
          })
        );

        setUsers((prevUsers: any) =>
          prevUsers.map((u: any) =>
            u.id === user.id ? { ...u, status: !u.status } : u
          )
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while blocking user",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleLoginAs = (user: any) => {
    document.cookie = `switchuser=${user.email}`;
    window.location.href = "/";
  };

  const handleSearch = (value: string | null) => {
    if (!value) {
      return;
    }
    setSearchKey(value);
  };

  return (
    <PageWrapper>
      <div className="acces-commerciaux-container">
        <h1>Création / Modification accès commerciaux</h1>

        <div className="acces-commerciaux-search">
          <Search
            handleSearch={handleSearch}
            placeholder="Rechercher un utilisateur par prénon/nom"
          />
        </div>

        {!searchKey && (
          <div className="acces-commerciaux-create">
            <Button
              text="Créer un nouveau commercial"
              size="sm"
              block
              to={() => navigate("/acces-commerciaux/add")}
            />
          </div>
        )}
      </div>

      {users && users.length > 0 && (
        <div className="user-listing">
          <Grid container spacing={3}>
            {users.map((user: any) => (
              <Grid key={user.id} item xs={12} md={6}>
                <div className="user-listing-item">
                  <h3 className="user-listing-title">
                    {user.firstName} {user.lastName}
                  </h3>
                  <div className="user-listing-actions">
                    <Button onPress={() => handleEdit(user)} text="MODIFIER" />
                    <Button
                      onPress={() => handleResetPassword(user)}
                      text="Réinitialiser mot de passe"
                    />
                    <Button
                      onPress={() => handleBlock(user)}
                      text={user.status ? "Bloquer" : "Débloquer"}
                    />
                    <Button
                      onPress={() => handleLoginAs(user)}
                      text="SE LOGUER EN TANT QUE"
                    />
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {users && users.length === 0 && searchKey && (
        <div className="text-center">Aucun résultat trouvé.</div>
      )}

      {searchKey && (
        <Pagination
          apiData={`/users?name=${searchKey}`}
          getCurrentItems={(current) => {
            if (!areObjectsEqual(current, users)) {
              setUsers(current);
            }
          }}
        />
      )}
    </PageWrapper>
  );
}
