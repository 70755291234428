import Button from "../components/Button";
import PageWrapper from "../components/Layout/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import CFCApi from "../apis";
import NomenclatureForm from "../components/NomenclatureForm";
import useNomenclature from "../hooks/useNomenclature";

export default function AddNomenclatures() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let {
    state: { itemData },
  } = useLocation();

  const { formData, setFormData, handleInputChange, handleAddSubCategory } =
    useNomenclature(itemData);

  const handleSave = async () => {
    try {
      // Validation
      const isEmptyCategory = !formData.category.trim();
      const isEmptySubCategory = formData.subCategories.some(
        (subCategory) => !subCategory.name.trim()
      );

      if (isEmptyCategory || isEmptySubCategory) {
        dispatch(
          setModel({
            type: "error",
            content: "Veuillez remplir tous les champs avant de sauvegarder",
            onClose: () => dispatch(setModel(null)),
          })
        );
        return;
      }

      dispatch(startLoading());

      // Determine the endpoint and format based on itemData.type
      let endpoint, formatKey;

      switch (itemData.type) {
        case "rdv":
          endpoint = "/appointment_types";
          formatKey = "subAppointmentTypes";
          break;
        case "folder":
          endpoint = "/types";
          formatKey = "subContactTypes";
          break;
        default:
          return;
      }

      // Transforming formData structure
      const values = {
        name: formData.category,
        [formatKey]: formData.subCategories.map((subCategory) => ({
          name: subCategory.name,
        })),
      };

      const response = await CFCApi.post(endpoint, values);

      if (response) {
        dispatch(
          setModel({
            type: "success",
            content: "Nomenclature créé avec succès",
            onClose: () => {
              navigate("/nomenclatures");
              dispatch(setModel(null));
            },
          })
        );
      } else {
        dispatch(
          setModel({
            type: "error",
            content: "An unexpected error occurred while creating Nomenclature",
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error: any) {
      
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <PageWrapper>
      <div className="nomenclatures">
        <h1>{`Ajout de type de ${
          itemData.type === "folder" ? "dossier" : "rendez-vous"
        }`}</h1>

        <NomenclatureForm
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          handleAddSubCategory={handleAddSubCategory}
        />

        <div className="button-wrapper form-buttons">
          <Button
            text="RETOUR"
            size="sm"
            onPress={() => navigate("/nomenclatures")}
          />
          <Button text="SAUVEGARDER" size="sm" onPress={handleSave} />
        </div>
      </div>
    </PageWrapper>
  );
}
