import { Fragment, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import Grid from "../components/Layout/Grid";
import Button from "../components/Button";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  Link,
} from "react-router-dom";
import Icon from "../components/Icon";
import Popup from "../components/Popup";
import FormGenerator from "../components/Form";
import useBOStrucutres from "../hooks/useBOStructures";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import { SubStructure } from "../types";
import Select from "react-select";
import { useEffect } from "react";
import useTrigger from "../hooks/useTrigger";
import CFCApiHydra from "../apis/CFCApiHydra";

export default function AddBOStructureSubCategories() {
  const { triggerLoading } = useTrigger();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [posteOptions, setPosteOptions] = useState<any>(null);

  useEffect(() => {
    const getJobs = () => {
      triggerLoading(true);
      CFCApiHydra.get(`/jobs?pagination=false`)
        .then((jobsResponse) => {
          const jobsOptions = jobsResponse["hydra:member"].map((job: any) => ({
            label: job.name,
            value: job["@id"],
          }));
          setPosteOptions(jobsOptions);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          triggerLoading(false);
        });
    };
    getJobs();
  }, []);

  const structureId = searchParams.get("id") || location?.state?.id;

  const {
    structureName,
    handleRootNameChange,
    infoFormFields,
    information,
    handleInfoSave,
    handleReturn,
    toggleInformation,
    subStructures,
    handleAddLevel,
    handleDelete,
    handleStrInputsChange,
    handleSelectChange,
    handleSubmit,
    getJobsDefault,
  } = useBOStrucutres(structureId, location?.state?.rootStructureData);

  const renderSecondLevel = (innerSubStructure: any, pi: number) => {
    return innerSubStructure?.subStructures?.map(
      (subSubStr: any, i: number) => (
        <div key={i} className="sub-structure-container">
          <button
            className="btn-information"
            onClick={() => toggleInformation(i, pi)}
          >
            Adresse postale
            <Icon size={12} icon="note-pencil" />
          </button>
          {subSubStr["@id"] && (
            <Link
              className="link-edit"
              to={`/create-bo-sub-structures?id=${extractIdFromStrings(
                subSubStr["@id"]
              )}`}
            >
              <Icon size={12} icon="note-pencil" />
              Ajouter un sous-niveau
            </Link>
          )}
          <div className="form-group form-group-inline">
            <div className="input-wrapper">
              <label htmlFor="typeName">Niveau</label>
              <input
                className="form-control"
                type="text"
                name="typeName"
                value={subSubStr.typeName || ""}
                onChange={(e) => handleStrInputsChange(e, i, "typeName", pi)}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                type="text"
                name="name"
                value={subSubStr.name || ""}
                onChange={(e) => handleStrInputsChange(e, i, "name", pi)}
              />
            </div>

            {posteOptions.length > 0 && (
              <div className="input-wrapper">
                <label htmlFor="name">Poste</label>
                <Select
                  isMulti={true}
                  className="form-control-select"
                  classNamePrefix="select"
                  name="jobs"
                  options={posteOptions}
                  defaultValue={() =>
                    getJobsDefault(subSubStr?.jobs, posteOptions)
                  }
                  onChange={(value: any) =>
                    handleSelectChange(value, i, "jobs", pi)
                  }
                />
              </div>
            )}
            <div className="input-wrapper input-actions">
              {subSubStr.isSecondLevel && (
                <input
                  className="form-control"
                  type="checkbox"
                  name="isTreeRepresentative"
                  checked={subSubStr.isTreeRepresentative}
                  onChange={(e) =>
                    handleStrInputsChange(e, i, "isTreeRepresentative", pi)
                  }
                />
              )}

              {subSubStr["@id"] && (
                <Button
                  iconSize={18}
                  onPress={() =>
                    handleDelete(extractIdFromStrings(subSubStr["@id"]))
                  }
                  icon="trash"
                />
              )}
            </div>
          </div>
        </div>
      )
    );
  };

  const renderFirstLevel = () => {
    return (
      <div className="setting-actions ">
        <h3 className="title">
          {structureId ? "Modification" : "Création"} des structures{" "}
          {structureName}
        </h3>
        <div className="structure">
          <div className="form-group">
            <div className="input-wrapper">
              <input
                className="form-control"
                type="text"
                name="name"
                value={structureName || ""}
                onChange={(e) => handleRootNameChange(e, "name")}
              />
              <p>
                La structure a été créée vous pouvez maintenant ajouter des
                niveaux
              </p>
            </div>
          </div>
        </div>
        {posteOptions && (
          <Fragment>
            <div className="sub-structure">
              <h4>Ajouter {structureName} Sous Structure</h4>
              {subStructures &&
                subStructures.map((subStructure: SubStructure, pi: number) => (
                  <div key={pi} className="sub-structure-container">
                    <button
                      className="btn-information"
                      onClick={() => toggleInformation(pi)}
                    >
                      Adresse postale
                      <Icon size={12} icon="note-pencil" />
                    </button>
                    {subStructure["@id"] && (
                      <Link
                        className="link-edit"
                        to={`/create-bo-sub-structures?id=${extractIdFromStrings(
                          subStructure["@id"]
                        )}`}
                      >
                        <Icon size={12} icon="note-pencil" />
                        Ajouter un sous-niveau
                      </Link>
                    )}

                    <div className="form-group form-group-inline">
                      <div className="input-wrapper">
                        <label htmlFor="typeName">Niveau</label>
                        <input
                          className="form-control"
                          type="text"
                          name="typeName"
                          value={subStructure.typeName || ""}
                          onChange={(e) =>
                            handleStrInputsChange(e, pi, "typeName", null)
                          }
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={subStructure.name || ""}
                          onChange={(e) =>
                            handleStrInputsChange(e, pi, "name", null)
                          }
                        />
                      </div>
                      {posteOptions.length > 0 && (
                        <div className="input-wrapper">
                          <label htmlFor="name">Poste</label>
                          <Select
                            isMulti={true}
                            className="form-control-select"
                            classNamePrefix="select"
                            name="jobs"
                            options={posteOptions}
                            value={getJobsDefault(
                              subStructure?.jobs,
                              posteOptions
                            )}
                            onChange={(value: any) =>
                              handleSelectChange(value, pi, "jobs", null)
                            }
                          />
                        </div>
                      )}
                      <div className="input-wrapper input-actions">
                        {subStructure.isSecondLevel && (
                          <input
                            className="form-control"
                            type="checkbox"
                            name="isTreeRepresentative"
                            checked={subStructure.isTreeRepresentative}
                            onChange={(e) =>
                              handleStrInputsChange(
                                e,
                                pi,
                                "isTreeRepresentative",
                                null
                              )
                            }
                          />
                        )}
                        {subStructure["@id"] && (
                          <Button
                            onPress={() =>
                              handleDelete(
                                extractIdFromStrings(subStructure["@id"])
                              )
                            }
                            iconSize={18}
                            icon="trash"
                          />
                        )}
                      </div>
                    </div>
                    <div className="sub-substructure">
                      {renderSecondLevel(subStructure, pi)}
                      <Button
                        className="btn"
                        text="+ de niveaux"
                        onPress={() => handleAddLevel(pi)}
                      />
                    </div>
                  </div>
                ))}
              <Button
                className="btn"
                text="+ de niveaux"
                onPress={handleAddLevel}
              />
            </div>
            <div className="actions">
              <Button text="RETOUR" onPress={() => navigate(-1)} />
              <Button text="sauvegarder" onPress={handleSubmit} />
            </div>
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid xs={12} md={12} justifyContent="center">
          {renderFirstLevel()}
          {information && (
            <Popup>
              <FormGenerator
                fields={infoFormFields}
                submitButtonText="Sauvegarder"
                widthReturn
                handleReturn={handleReturn}
                onSubmit={handleInfoSave}
              />
            </Popup>
          )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
