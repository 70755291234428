import React from "react";
import Icon from "../Icon";
import { Link, useNavigate } from "react-router-dom";

interface LinkInputFieldProps {
  label: string;
  defaultValue?: string;
  url?: string;
  data?: any;
  validateForm?: any;
  formik: any;
  concatData?: boolean;
}

const LinkInputField: React.FC<LinkInputFieldProps> = ({
  label,
  defaultValue,
  url,
  data,
  formik,
  concatData,
}) => {
  const navigate = useNavigate();

  const handleClick = async (e: any) => {
    e.preventDefault();
    for (const key in formik.values) {
      if (formik.values.hasOwnProperty(key)) {
        formik.setFieldTouched(key, true);
      }
    }
    formik.validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        if (url) {
          navigate(url, {
            state: {
              formValues: formik.values,
              data,
            },
          });
        }
      }
    });
  };
  if (url) {
    return (
      <Link
        to={url}
        onClick={handleClick}
        state={{
          formValues: formik.values,
          data,
        }}
        className="link-input-field"
      >
        <div className="form-group">
          <div className="label-wrapper">
            <label>{label}</label>
          </div>
          <div className="input-wrapper">
            <div className="form-control">
              <span className="link-input-field-icon">
                <Icon icon="arrow-right" size={13} />
              </span>
              <span>{defaultValue || ""}</span>
            </div>
            <div />
          </div>
        </div>
      </Link>
    );
  } else {
    return null;
  }
};

export default LinkInputField;
