import { useEffect, useState } from "react";
import Button from "../components/Button";
import PageWrapper from "../components/Layout/PageWrapper";
import List from "../components/List";
import Search from "../components/Search";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Pagination from "../components/Pagination";
import Appointment from "../components/Appointment";
import moment from "moment";
import queryFormatDate from "../helpers/queryFormatDate";

export default function EspaceCommercial() {
  const navigate = useNavigate();

  const [isSemain, setIsSemain] = useState<boolean>(false);

  const [contactsData, setContactsData] = useState<any[]>([]);

  const [dossiersData, setDossiersData] = useState<any[]>([]);

  const [historyData, setHistoryData] = useState<any[]>([]);

  const [appointmentsData, setAppointementsData] = useState<any>([]);

  const [searchKey, setSearchKey] = useState<null | string>(null);

  const [contactsEndPoint, setContactsEndPointData] =
    useState<string>("/users/my-contacts");
  const [dossiersEndPoint, setDossiersEndPoint] =
    useState<string>("/users/my-folders");
  const [historiesEndPoint, setHistoriesEndPoint] =
    useState<string>("/users/my-history");

  const setSeachEndpoints = (
    setState: any,
    key: string | null,
    api: string
  ) => {
    setState(searchKey ? `${api}?name=${key}` : api);
  };

  useEffect(() => {
    setSeachEndpoints(setContactsEndPointData, searchKey, "/users/my-contacts");
    setSeachEndpoints(setDossiersEndPoint, searchKey, "/users/my-folders");
    setSeachEndpoints(setHistoriesEndPoint, searchKey, "/users/my-history");
  }, [searchKey]);

  const handleSearch = (value: string | null) => {
    setSearchKey(value);
  };

  const renderList = (
    stateSet: any,
    stateData: any,
    apiData: string,
    title: string,
    navigateTo: string
  ) => {
    return (
      <Grid item xs={12}>
        <List title={title} useStateData={true} items={stateData} navigateTo={navigateTo} />
        <Pagination
          apiData={apiData}
          getCurrentItems={(current) => {
            const formedData = current.map((item: any) => ({
              name: `${item.firstName} ${item.lastName}`,
              id: item["@id"],
            }));
            if (!areObjectsEqual(formedData, stateData)) {
              stateSet(formedData);
            }
          }}
        />
      </Grid>
    );
  };

  return (
    <PageWrapper>
      <h1>Dossiers/Contacts</h1>
      <Search handleSearch={handleSearch} />
      <Grid container>
        {renderList(
          setContactsData,
          contactsData,
          contactsEndPoint,
          "Contact",
          "/edit-contact"
        )}
        {renderList(
          setDossiersData,
          dossiersData,
          dossiersEndPoint,
          "Dossiers",
          "/edit-dossier"
        )}

        <Grid
          item
          xs={12}
          sx={{
            mx: {
              xs: "auto",
              md: "auto",
            },
            mt: {
              xs: 1,
            },
            mb: {
              xs: 3,
            },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            text="Ajouter un contact"
            icon="user-plus"
            size="sm"
            to={() => navigate("/add-contact")}
          />
        </Grid>
        {renderList(
          setHistoryData,
          historyData,
          historiesEndPoint,
          "Historique",
          "/fiche-history"
        )}
        <Grid item xs={12}>
          <div className="appointments">
            <div className="head">
              <h2>Prochains rendez-vous</h2>
              <div className="filters-wrapper">
                <ul>
                  <li className={!isSemain ? "active" : ""}>
                    <button id="jour" onClick={() => setIsSemain(false)} />
                    <label htmlFor="jour">Jour</label>
                  </li>
                  <li className={isSemain ? "active" : ""}>
                    <label htmlFor="semaine">Semaine</label>
                    <button id="semaine" onClick={() => setIsSemain(true)} />
                  </li>
                </ul>
              </div>
            </div>
            {appointmentsData && appointmentsData.length > 0 ? (
              appointmentsData.map((appointment: any, index: number) => (
                <Appointment key={index} appointment={appointment} />
              ))
            ) : (
              <p className="message">No prochains rendez-vous</p>
            )}
          </div>

          <Pagination
            apiData={
              !isSemain
                ? `/appointments?${queryFormatDate(
                    moment().format("DD-MM-YYYY")
                  )}`
                : `/appointments?ofWeek=true`
            }
            getCurrentItems={(current) => {
              if (!areObjectsEqual(current, appointmentsData)) {
                setAppointementsData(current);
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mx: {
              xs: "auto",
              md: "auto",
            },
            mt: {
              xs: 1,
            },
            mb: {
              xs: 3,
            },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            text="RENDEZ-VOUS"
            icon="plus"
            size="sm"
            to={() => navigate("/agenda/add-rdv")}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
