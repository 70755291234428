import { useEffect, useState } from "react";
import { Field } from "../components/Form";
import { SubStructure } from "../types";
import { useSearchParams, useNavigate } from "react-router-dom";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import { useDispatch } from "react-redux";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import CFCApiHydra from "../apis/CFCApiHydra";

const initialInfoFormField: Field[] = [
  {
    name: "address",
    type: "text",
    label: "Adresse",
    grid: {
      xs: 12,
      md: 12,
    },
    required: false,
  },
  {
    name: "zipCode",
    type: "text",
    label: "Code postal",
    grid: {
      xs: 12,
      md: 12,
    },
    required: false,
  },
  {
    name: "city",
    type: "text",
    label: "Ville",
    grid: {
      xs: 12,
      md: 12,
    },
    required: false,
  },
];

const useBOStructures = (structureId: number | string, rootStructure: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  const [structureName, setStructureName] = useState<string>(
    rootStructure?.name || ""
  );
  const [rootStructureData, setRootStructureData] =
    useState<any>(rootStructure);

  const [rootFormValues, setRootFormValues] = useState<{ name: string }>(
    rootStructureData
  );

  const [subStructures, setSubStructures] = useState<null | SubStructure[]>(
    null
  );
  const [infoFormFields, setInfoFormFields] = useState<Field[]>([]);
  const [information, setInformation] = useState<{
    index: number;
    parentIndex?: number;
  } | null>(null);

  const getJobsDefault = (values: any, posteOptions: []) => {
    if (!values || !posteOptions) return;
    const defaultValue: any = [];
    values.forEach((value: string) => {
      posteOptions.forEach((post: { value: string }) => {
        if (post.value === value) defaultValue.push(post);
      });
    });
    return defaultValue;
  };

  const toggleInformation = (index: number, pi?: number) => {
    if (!subStructures) return;
    let updatedInfoFormFields: Field[] = JSON.parse(
      JSON.stringify(initialInfoFormField)
    );

    if (typeof pi === "number") {
      updatedInfoFormFields = [...updatedInfoFormFields].map(
        (formField: Field) => {
          if ((subStructures as any)[pi].subStructures[index][formField.name]) {
            formField.defaultValue = (subStructures as any)[pi].subStructures[
              index
            ][formField.name];
          }
          return formField;
        }
      );
      setInfoFormFields(updatedInfoFormFields);
      setInformation({ index, parentIndex: pi });
      return;
    }
    updatedInfoFormFields = [...updatedInfoFormFields].map(
      (formField: Field) => {
        if (subStructures[index][formField.name]) {
          formField.defaultValue = subStructures[index][formField.name];
        }
        return formField;
      }
    );
    setInfoFormFields(updatedInfoFormFields);
    setInformation({ index });
  };

  const handleInfoSave = (values: any) => {
    if (!information || !subStructures) return;

    const newUpdSubStructures = [...subStructures];
    if (typeof information.parentIndex === "number") {
      (newUpdSubStructures as any)[information?.parentIndex].subStructures[
        information.index
      ] = {
        ...(newUpdSubStructures as any)[information?.parentIndex].subStructures[
          information?.index
        ],
        ...values,
      };
      setSubStructures(newUpdSubStructures);
      setInformation(null);
      return;
    }

    newUpdSubStructures[information?.index] = {
      ...newUpdSubStructures[information?.index],
      ...values,
    };
    setSubStructures(newUpdSubStructures);
    setInformation(null);
  };

  const handleReturn = () => {
    setInformation(null);
  };

  const triggerAddError = () => {
    dispatch(
      setModel({
        type: "error",
        content: "veuillez remplir le champ avant d'en ajouter un nouveau",
        onClose: () => dispatch(setModel(null)),
      })
    );
  };

  const handleAddLevel = (index?: number) => {
    if (!subStructures) return;
    let lastOne: any = null;
    if (typeof index === "number") {
      // Start Check Parnt is filled
      const parent = subStructures[index];
      if (parent?.name?.trim() === "" || parent?.typeName?.trim() === "") {
        triggerAddError();
        return;
      }
      // End Check Parnt is filled
      console.log(subStructures[index]?.subStructures);
      if (!subStructures[index]?.subStructures) return;
      lastOne = (subStructures as any)[index].subStructures[
        (subStructures as any)[index].subStructures.length - 1
      ];
    } else {
      lastOne = subStructures[subStructures.length - 1];
    }
    if (
      lastOne &&
      (lastOne.name.trim() === "" || lastOne.typeName.trim() === "")
    ) {
      triggerAddError();
      return;
    }

    if (typeof index === "number") {
      const updatedSubStructures = [...subStructures];
      updatedSubStructures[index].subStructures = [
        ...(updatedSubStructures as any)[index].subStructures,
        {
          name: "",
          typeName: "",
          isTreeRepresentative: false,
        },
      ];
      setSubStructures(updatedSubStructures);
      return;
    }

    setSubStructures([
      ...subStructures,
      {
        name: "",
        typeName: "",
        isTreeRepresentative: false,
        subStructures: [],
      },
    ]);
  };

  const handleRootNameChange = (e: any, target: string) => {
    const value = e.target.value;
    const updatedFormValue = { ...rootFormValues };
    if (target === "name") {
      updatedFormValue.name = value;
    }
  };

  const handleDelete = async (id: any) => {
    try {
      dispatch(startLoading());
      await CFCApiHydra.delete(`/structures/${id}`);
      const structure = await CFCApiHydra.get(
        `/structures/${structureId || extractIdFromStrings(id)}`
      );
      setSubStructures(structure.subStructures);
      const newRootStructure = { ...structure };
      delete newRootStructure.subStructures;
      setRootFormValues(newRootStructure);
      setRootStructureData(newRootStructure || null);
    } catch (error: any) {
      dispatch(
        setModel({
          type: "error",
          content: error.message || "error",
          onClose: () => dispatch(setModel(null)),
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSelectChange = (
    value: any = [],
    targetIndex: number,
    targetName: string,
    targetParentIndex?: number | null
  ) => {
    if (!subStructures) return;
    const formatedValue = value.map((val: any) => val.value);
    const newUpdatedSubStructures = [...subStructures];
    if (typeof targetParentIndex === "number") {
      (newUpdatedSubStructures as any)[targetParentIndex].subStructures[
        targetIndex
      ][targetName] = formatedValue;

      setSubStructures(newUpdatedSubStructures);
      return;
    }

    newUpdatedSubStructures[targetIndex][targetName] = formatedValue;
    setSubStructures(newUpdatedSubStructures);
  };

  const handleStrInputsChange = (
    e: any,
    targetIndex: number,
    targetName: string,
    targetParentIndex?: number | null
  ) => {
    if (!subStructures) return;
    let value: any = e.target.value;
    const newUpdatedSubStructures = [...subStructures];

    if (typeof targetParentIndex === "number") {
      if (targetName === "isTreeRepresentative") {
        value = !(newUpdatedSubStructures as any)[targetParentIndex]
          .subStructures[targetIndex][targetName];
        (newUpdatedSubStructures as any)[targetParentIndex].subStructures[
          targetIndex
        ][targetName] = value;
      }
      (newUpdatedSubStructures as any)[targetParentIndex].subStructures[
        targetIndex
      ][targetName] = value;

      setSubStructures(newUpdatedSubStructures);
      return;
    }

    if (targetName === "isTreeRepresentative") {
      value = !newUpdatedSubStructures[targetIndex][targetName];
    }

    newUpdatedSubStructures[targetIndex][targetName] = value;
    setSubStructures(newUpdatedSubStructures);
  };

  const handleSubmit = async () => {
    if (!subStructures) return;
    const payloadRootStructure = (({
      address,
      city,
      isRootStructure = false,
      isTreeRepresentative,
      name,
      zipCode,
      jobs,
    }) => ({
      "@id": rootStructureData["@id"],
      id: rootStructureData["@id"]
        ? extractIdFromStrings(rootStructureData["@id"])
        : null,
      address,
      city,
      isRootStructure,
      isTreeRepresentative,
      name,
      zipCode,
      jobs,
    }))(rootStructureData);

    const pyload = {
      ...payloadRootStructure,
      subStructures: subStructures.filter(
        (subSt) => subSt.name.trim() !== "" && subSt.typeName.trim() !== ""
      ),
    };
    if (!structureId) {
      pyload.isRootStructure = true;
      (pyload as any).rootType = rootStructureData.rootType;
    }
    pyload.subStructures = pyload.subStructures.map((subSt: any) => {
      if (subSt["@id"]) {
        subSt.id = extractIdFromStrings(subSt["@id"]);
      }
      if (subSt?.subStructures?.length === 0) {
        delete subSt.subStructures;
      } else if (subSt?.subStructures) {
        subSt.subStructures = subSt.subStructures.map((subSubSt: any) => {
          if (subSubSt["@id"]) {
            subSubSt.id = extractIdFromStrings(subSubSt["@id"]);
          }
          return subSubSt;
        });
      }
      return subSt;
    });

    dispatch(startLoading());
    const req = async () => {
      let createdStructure: any;
      if (structureId) {
        await CFCApiHydra.patch(
          `/structures/${structureId}/subStructures`,
          pyload
        );
      } else {
        createdStructure = await CFCApiHydra.post("/structures", pyload);
        const newId: any = extractIdFromStrings(createdStructure["@id"]);
        setSearchParams({ id: newId });
      }

      const structure = await CFCApiHydra.get(
        `/structures/${
          structureId || extractIdFromStrings(createdStructure["@id"])
        }`
      );
      setSubStructures(structure.subStructures);
      const newRootStructure = { ...structure };
      delete newRootStructure.subStructures;
      setRootFormValues(newRootStructure);
      setRootStructureData(newRootStructure || null);
    };
    await req()
      .then((res: any) =>
        console.log("Structure is created successfully !", res)
      )
      .catch((error: any) => {
        dispatch(
          setModel({
            type: "error",
            content: error.message || "error",
            onClose: () => dispatch(setModel(null)),
          })
        );
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  useEffect(() => {
    if (!structureId) {
      setSubStructures([
        {
          name: "",
          typeName: "",
          isTreeRepresentative: false,
          subStructures: [],
          jobs: [],
        },
      ]);
    }
  }, []);

  const checkAndSetStrucutreName = () => {
    if (!subStructures) return;

    if (
      rootStructureData?.structure?.isTreeRepresentative &&
      !rootStructureData?.isRootStructure
    ) {
      setStructureName(rootStructureData.structure.name);
    } else {
      setStructureName(rootStructureData.name);
    }
  };

  useEffect(() => {
    if (!subStructures) return;
    checkAndSetStrucutreName();
  }, [subStructures]);

  useEffect(() => {
    if (structureId) {
      (async () => {
        try {
          dispatch(startLoading());
          const structure = await CFCApiHydra.get(`/structures/${structureId}`);
          console.log(structure);
          if (structure?.subStructures?.length > 0) {
            setSubStructures(structure.subStructures);
          } else {
            setSubStructures([
              {
                name: "",
                typeName: "",
                isTreeRepresentative: false,
                subStructures: [],
                jobs: [],
              },
            ]);
          }
          const structureData = { ...structure };
          delete structureData.subStructures;
          setRootFormValues(structureData);
          setRootStructureData(structureData || null);
        } catch (error: any) {
          dispatch(
            setModel({
              type: "error",
              content: error.message || "error",
              onClose: () => {
                navigate(-1);
                dispatch(setModel(null));
              },
            })
          );
        } finally {
          dispatch(stopLoading());
        }
      })();
    }
  }, [structureId]);

  return {
    structureName,
    rootStructureData,
    infoFormFields,
    toggleInformation,
    subStructures,
    setSubStructures,
    information,
    setInformation,
    handleAddLevel,
    handleRootNameChange,
    setRootStructureData,
    setRootFormValues,
    rootFormValues,
    handleDelete,
    handleStrInputsChange,
    handleSubmit,
    handleInfoSave,
    handleReturn,
    handleSelectChange,
    getJobsDefault,
  };
};

export default useBOStructures;
