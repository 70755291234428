import React from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";

interface FileFieldProps {
  field: Field;
  formik: any;
}

const FileField: React.FC<FileFieldProps> = ({ field, formik }) => {
  return (
    <div key={field.name} className="form-group">
      <div className="input-wrapper">
        <div className="form-control form-control-file">
          <span className="placeholder">{field.placeholder}</span>
          <span className="form-control-file-icon">
            <Icon icon="paperclip" size={16} />
          </span>
          <input
            className="form-control-file-input"
            readOnly={field.isReadOnly}
            type={field.type}
            id={field.name}
            accept={field.accept || "*/*"}
            {...formik.getFieldProps(field.name)}
            value={undefined}
            onChange={(e: Event) =>
              formik.setFieldValue(
                field.name,
                (e.currentTarget as HTMLInputElement).files
              )
            }
            multiple
          />
        </div>
      </div>

      {formik.touched[field.name] && formik.errors[field.name] && (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      )}
    </div>
  );
};

export default FileField;
