import React, { useState } from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";
import { TagsInput } from "react-tag-input-component";
import Tooltip from "../Tooltip";

interface TextFieldProps {
  field: Field;
  formik: any;
}

const TagField: React.FC<TextFieldProps> = ({ field, formik }) => {
  const [tags, setTags] = useState<string[]>(field.defaultValue || []);

  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip && (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          )}
        </label>
        {field.action && (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        )}
      </div>
      <div className="input-wrapper">
        <TagsInput
          value={tags}
          classNames={{ input: "form-control-tag" }}
          onChange={(val) => {
            setTags(val);
            formik.setValues({ ...formik.values, [field.name]: val });
          }}
          separators={["Entre", ",", " "]}
          placeHolder=""
        />
        {field.isEditable && (
          <span className="editable-icon">
            <Icon icon="note-pencil" size={15} />
          </span>
        )}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default TagField;
