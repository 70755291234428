const queryFormatDate = (inputDateString: string) => {
  // Parse the input date string
  const parts = inputDateString.split("-");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Create a Date object with the parsed values
  const dateObject = new Date(year, month - 1, day);

  // Format the output string
  return `day=${dateObject.getDate()}&month=${
    dateObject.getMonth() + 1
  }&year=${dateObject.getFullYear()}`;
};

export default queryFormatDate;
