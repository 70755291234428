import Cookies from "js-cookie";
import decode from "jwt-decode";

const JWTDecode = (tokenData?: string) => {
  const token = tokenData || Cookies.get("token");
  if (token) {
    return decode(token);
  } else {
    return null;
  }
};
export default JWTDecode;
