import React, { useEffect } from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

interface TextAreaFieldProps {
  field: Field;
  formik: any;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ field, formik }) => {
  let fieldToTrigger = null;
  if (field.trigger) {
    fieldToTrigger = formik.values[field.trigger.name];
  }

  const fieldValue = formik.values[field.name] || "";

  useEffect(() => {
    if (field?.trigger?.name && field?.trigger?.name !== "") {
      if (
        formik.values[field?.trigger?.name] &&
        formik.values[field?.trigger?.name] !== ""
      ) {
        const newValue = field.trigger.triggerFunc(
          formik.values[field.trigger.name]
        );
        formik.setFieldValue(field.name, newValue);
      }
    }
  }, [fieldToTrigger]);

  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>

      <div className="input-wrapper input-wrapper-textarea">
        <textarea
          className="form-control form-control-textarea"
          type={field.type}
          id={field.name}
          {...formik.getFieldProps(field.name)}
          value={fieldValue}
        />
        {field.isEditable ? (
          <span className="editable-icon">
            <Icon icon="note-pencil" size={15} />
          </span>
        ) : null}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
      {field.details && field.details.length > 0 && (
        <ul className="form-list">
          {field.details.map((v: any) => (
            <li>{v.comment}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextAreaField;
