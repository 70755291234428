import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import Grid from "../components/Layout/Grid";
import CFCApiHydra from "../apis/CFCApiHydra";
import Icon from "../components/Icon";
import List from "../components/List";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Autocomplete from "../components/Form/Autocomplete";
import extractIdFromStrings from "../helpers/extractIdFromStrings";

export default function ActiviteCommerciale() {
  const dispatch = useDispatch();

  const [suiviData, setSuiviData] = useState<any>(null);
  const [isMonsuel, setIsMonsuel] = useState(true);
  const [isShowTerain, setIsShowTerain] = useState(false);
  const [contactsData, setContactsData] = useState<any[]>([]);
  const [dossiersData, setDossiersData] = useState<any[]>([]);

  const [contactsCount, setContactsCount] = useState<number>(0);
  const [dossiersCount, setDossiersCount] = useState<number>(0);

  const [contactsEndPoint, setContactsEndPointData] =
    useState<string>("/users/my-contacts");
  const [dossiersEndPoint, setDossiersEndPoint] =
    useState<string>("/users/my-folders");

  const [historyData, setHistoryData] = useState<any[]>([]);
  const [historiesEndPoint, setHistoriesEndPoint] =
    useState<string>("/users/my-history");
  const [suiviEndPoint, setSuiviEndPoint] = useState<string>(
    `/users/quantitative_monitoring?period=${isMonsuel ? "monthly" : "annual"}`
  );

  const [searchKey, setSearchKey] = useState<null | string>(null);
  const [user, setUser] = useState<any>(null);

  const setSeachEndpoints = (
    setState: any,
    key: string | null,
    api: string
  ) => {
    let endpoint = searchKey ? `${api}?user=${key}` : api;
    if (api.startsWith("/users/quantitative_monitoring?period")) {
      endpoint = searchKey ? `${api}&user=${key}` : api;
    }
    setState(endpoint);
  };

  useEffect(() => {
    setSeachEndpoints(setContactsEndPointData, searchKey, "/users/my-contacts");
    setSeachEndpoints(setDossiersEndPoint, searchKey, "/users/my-folders");
    setSeachEndpoints(setHistoriesEndPoint, searchKey, "/users/my-history");
    setSeachEndpoints(
      setSuiviEndPoint,
      searchKey,
      `/users/quantitative_monitoring?period=${
        isMonsuel ? "monthly" : "annual"
      }`
    );
  }, [searchKey]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());
        const suiviResponse = await CFCApiHydra.get(suiviEndPoint);
        setSuiviData(suiviResponse);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(stopLoading());
      }
    })();
  }, [suiviEndPoint]);

  const handleIsMonsuel = async (value: boolean) => {
    if (value === isMonsuel) return;
    dispatch(startLoading());
    const periodValue = value ? "monthly" : "annual";
    const userQuery = searchKey ? `&user=${searchKey}` : "";
    const suiviResponse = await CFCApiHydra.get(
      `/users/quantitative_monitoring?period=${periodValue}${userQuery}`
    );
    setSuiviData(suiviResponse);
    setIsMonsuel(value);
    dispatch(stopLoading());
  };

  const renderSuivi = () => {
    if (!suiviData) return;
    const {
      rdvTerrainCount,
      newContactsCount,
      folderSendCount,
      financedFolderCount,
      financedAmount,
      turnover,
    } = suiviData;
    return (
      <Grid sm={12} md={12}>
        <div className="suivi-results">
          <div className="top">
            <h3>Suivi Quantitatif</h3>
            <ul className="actions">
              <li>
                <button
                  onClick={() => handleIsMonsuel(true)}
                  className={`${isMonsuel ? "active" : ""}`}
                >
                  Mensuel
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleIsMonsuel(false)}
                  className={`${!isMonsuel ? "active" : ""}`}
                >
                  Annuel
                </button>
              </li>
            </ul>
          </div>
          <div className="bottom">
            {suiviData && (
              <ul>
                {rdvTerrainCount && (
                  <Fragment>
                    <li>
                      <span className="key">
                        Nombre de rendez-vous terrain :
                      </span>
                      <span className="value">{rdvTerrainCount?.count}</span>
                      <button
                        className="btn-action"
                        onClick={() => setIsShowTerain(!isShowTerain)}
                      >
                        <Icon
                          icon={isShowTerain ? "eye" : "eye-slash"}
                          size={16}
                        />
                      </button>
                    </li>
                    {isShowTerain && rdvTerrainCount?.subType?.length > 0 && (
                      <ul>
                        {rdvTerrainCount?.subType.map(
                          (subType: { name: string; value: number }) => (
                            <li>
                              <span className="key">{`Nombre de rendez-vous ${subType.name} :`}</span>
                              <span className="value">{subType.value}</span>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </Fragment>
                )}
                <li>
                  <span className="key">Nombre de nouveaux contacts : </span>
                  <span className="value">{newContactsCount}</span>
                </li>

                <li>
                  <span className="key">Nombre de dossiers envoyés : </span>
                  <span className="value">{folderSendCount}</span>
                </li>
                <li>
                  <span className="key">Nombre de dossiers financés :</span>
                  <span className="value">{financedFolderCount}</span>
                </li>
                <li>
                  <span className="key">Montant financé :</span>
                  <span className="value">{financedAmount}€</span>
                </li>
                <li>
                  <span className="key">CA net :</span>
                  <span className="value">{turnover}€</span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </Grid>
    );
  };

  const renderList = (
    stateSet: any,
    stateData: any,
    apiData: string,
    title: string,
    navigateTo: string,
    setItemsCount?: any
  ) => {
    return (
      <Grid item xs={12}>
        <List
          useStateData={true}
          title={title}
          items={stateData}
          navigateTo={navigateTo}
        />
        <Pagination
          apiData={apiData}
          getCurrentItems={(current) => {
            const formedData = current.map((item: any) => ({
              name: `${item.firstName} ${item.lastName}`,
              id: item["@id"],
            }));
            if (!areObjectsEqual(formedData, stateData)) {
              stateSet(formedData);
            }
          }}
          getTotalItems={(totalItems) => {
            setItemsCount(totalItems);
          }}
        />
      </Grid>
    );
  };

  return (
    <PageWrapper>
      <h1>
        Suivi de l’activité commerciale {user?.display && `(${user.display})`}
      </h1>
      <Autocomplete
        apiUrl="/users"
        onSuggestionClick={(v) => {
          setUser(v);
          setSearchKey(JSON.stringify(extractIdFromStrings(v.id)));
        }}
      />
      <Grid container>{renderSuivi()}</Grid>

      {renderList(
        setContactsData,
        contactsData,
        contactsEndPoint,
        contactsCount !== 0
          ? `Contacts en cours (${contactsCount})`
          : "Contacts en cours",
        "/edit-contact",
        setContactsCount
      )}

      {renderList(
        setDossiersData,
        dossiersData,
        dossiersEndPoint,
        dossiersCount !== 0
          ? `Dossiers en cours (${dossiersCount})`
          : "Dossiers en cours",
        "/edit-dossier",
        setDossiersCount
      )}

      {renderList(
        setHistoryData,
        historyData,
        historiesEndPoint,
        "Historique",
        "/fiche-history"
      )}
    </PageWrapper>
  );
}
