import React from 'react';
import { Field } from '../../components/Form';
import Tooltip from '../Tooltip';

interface RadioFieldProps {
  field: Field;
  formik: any;
}

const RadioField: React.FC<RadioFieldProps> = ({ field, formik }) => {
  const handleChange = (optionValue: string) => {
    formik.setFieldValue(field.name, optionValue);
  };

  return (
    <div key={field.name} className="form-group">
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>

      <div className="input-wrapper">
        {field.options?.map((option) => (
          <div key={option.value} className="radio-wrapper">
            <input
              type="radio"
              id={`${field.name}-${option.value}`}
              name={field.name}
              value={option.value}
              checked={formik.values[field.name] === option.value}
              onChange={() => handleChange(option.value)}
            />
            <label htmlFor={`${field.name}-${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default RadioField;
