import { createSlice } from "@reduxjs/toolkit";

interface AppState {
  loading: boolean;
  model: {
    type: string;
    content: string;
    onClose: () => void;
    action?: { text: string; func: any };
  } | null;
}

const initialState: AppState = {
  loading: false,
  model: null,
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
  },
});

export const { startLoading, stopLoading, setModel } = appSlice.actions;
export default appSlice.reducer;
