const setAPAFiled = (
  formFieldsToUpdate: any,
  APAResponse: any,
  apaIndex: number
) => {
  formFieldsToUpdate[apaIndex].options = APAResponse["hydra:member"].map(
    (opt: any) => ({
      label: `${opt.firstName} ${opt.lastName}`,
      value: opt["@id"],
      structure: opt["structure"],
    })
  );
};

const setTypeField = (
  formFieldsToUpdate: any,
  typesResponse: any,
  typesIndex: number
) => {
  formFieldsToUpdate[typesIndex].options = typesResponse["hydra:member"].map(
    (opt: any) => ({
      label: opt.name,
      value: opt["@id"],
    })
  );
};

export { setAPAFiled, setTypeField };
