import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useUsers = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const user = useSelector((state: any) => state?.user?.data);
  useEffect(() => {
    setIsAdmin(user?.roles?.includes("ROLE_ADMIN") || false);
  }, [user]);

  return {
    isAdmin,
  };
};

export default useUsers;
