import { useEffect, useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import Grid from "../components/Layout/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { setModel, startLoading, stopLoading } from "../state/slices/appSlice";
import { useDispatch, useSelector } from "react-redux";
import CFCApiHydra from "../apis/CFCApiHydra";
import checkObjectsEqual from "../helpers/checkObjectsEqual";
import isNumber from "../helpers/isNumber";

export default function AddBOStructure() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.app.loading);

  const [formAddRootStrucutre, setFormAddRootStrucutre] = useState<Field[]>([
    {
      name: "name",
      type: "text",
      label: "Nom de la structure",
      grid: {
        xs: 12,
        md: 12,
      },
      required: true,
    },
    {
      name: "address",
      type: "text",
      label: "Adresse",
      grid: {
        xs: 12,
        md: 12,
      },
      required: true,
    },
    {
      name: "zipCode",
      type: "text",
      label: "Code postal",
      grid: {
        xs: 12,
        md: 12,
      },
      required: true,
    },
    {
      name: "city",
      type: "text",
      label: "Ville",
      grid: {
        xs: 12,
        md: 12,
      },
      required: true,
    },
    {
      name: "jobs",
      type: "select",
      label: "Poste",
      options: [],
      isMulti: true,
      grid: {
        xs: 12,
        md: 12,
      },
      required: true,
    },
  ]);

  const [showForm, setShowForm] = useState<boolean>(false);

  const handleFormSubmit = async (values: any) => {
    values.rootType =
      rootStructure && rootStructure["@id"]
        ? rootStructure["@id"]
        : rootStructureId;

    if (rootStructureId) {
      const updatedInfo = values;
      delete updatedInfo.rootType;
      if (
        !checkObjectsEqual(values, {
          name: formAddRootStrucutre[0].defaultValue,
          address: formAddRootStrucutre[1].defaultValue,
          zipCode: formAddRootStrucutre[2].defaultValue,
          city: formAddRootStrucutre[3].defaultValue,
        })
      ) {
        dispatch(startLoading());
        await CFCApiHydra.patch(`/structures/${rootStructureId}`, updatedInfo);
        dispatch(startLoading());
      }
    }

    const query = rootStructureId ? `?id=${rootStructureId}` : `?`;

    navigate(`/create-bo-sub-structures${query}`, {
      state: {
        rootStructureData: values,
        rootStructureType: rootStructure,
      },
    });
  };

  const rootStructure = location?.state?.rootStructure;
  const rootStructureId = location?.state?.rootStructureId;

  useEffect(() => {
    if (rootStructure || isNumber(rootStructureId)) {
      if (isNumber(rootStructureId)) {
        (async () => {
          try {
            dispatch(startLoading());
            const jobs = await CFCApiHydra.get(`/jobs?pagination=false`);
            const rootStrRes = await CFCApiHydra.get(
              `/structures/${rootStructureId}`
            );
            const updateFormFields = [...formAddRootStrucutre];

            updateFormFields[4].options = jobs["hydra:member"].map(
              (job: any) => ({
                label: job.name,
                value: job["@id"],
              })
            );

            updateFormFields[0].defaultValue = rootStrRes.name;
            updateFormFields[1].defaultValue = rootStrRes.address;
            updateFormFields[2].defaultValue = rootStrRes.zipCode;
            updateFormFields[3].defaultValue = rootStrRes.city;
            updateFormFields[4].defaultValue = rootStrRes.jobs;
            setFormAddRootStrucutre(updateFormFields);
            setShowForm(true);
          } catch (error: any) {
            dispatch(
              setModel({
                type: "error",
                content:
                  error.message ||
                  "Error: No RootType or root structure Id passed",
                onClose: () => {
                  navigate("/gestion-des-structures");
                  dispatch(setModel(null));
                },
              })
            );
          } finally {
            dispatch(stopLoading());
          }
        })();
      } else {
        (async () => {
          try {
            dispatch(startLoading());
            const jobs = await CFCApiHydra.get(`/jobs?pagination=false`);
            const updateFormFields = [...formAddRootStrucutre];

            updateFormFields[4].options = jobs["hydra:member"].map(
              (job: any) => ({
                label: job.name,
                value: job["@id"],
              })
            );
            setFormAddRootStrucutre(updateFormFields);
            setShowForm(true);
          } catch (error: any) {
            console.log(error);
          } finally {
            dispatch(stopLoading());
          }
        })();
        setShowForm(true);
      }
      return;
    }

    dispatch(
      setModel({
        type: "error",
        content: "Error: No RootType or root structure Id  passed",
        onClose: () => {
          navigate("/gestion-des-structures");
          dispatch(setModel(null));
        },
      })
    );
  }, []);

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12} justifyContent="center">
          <div className="setting-actions ">
            <h3 className="title">
              {!rootStructureId ? "Création" : "Modification"} des structures{" "}
              {rootStructure?.name || formAddRootStrucutre[0].defaultValue}
            </h3>

            <Grid container spacing={0}>
              <Grid>
                {!isLoading && showForm && (
                  <FormGenerator
                    fields={formAddRootStrucutre}
                    submitButtonText={
                      !rootStructureId ? "SUIVANT" : "CONTINUER"
                    }
                    widthReturn
                    onSubmit={handleFormSubmit}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
