import { useState, useEffect } from "react";
import useTrigger from "./useTrigger";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import CFCApiHydra from "../apis/CFCApiHydra";
import CFCApi from "../apis/index";

const fields = [
  {
    name: "file_type",
    type: "select",
    label: "Type de fichier",
    options: [],
    grid: {
      xs: 12,
      md: 12,
    },
    required: true,
  },
  {
    name: "pieceJointe",
    type: "file",
    accept: ".jpg, .jpeg, .png, .pdf",
    label: "Pièces jointes",
    placeholder: "Ajouter des pièces jointes",
    grid: {
      xs: 12,
      md: 12,
    },
    required: true,
  },
];

const useAttachemts = () => {
  const { triggerError, triggerLoading } = useTrigger();

  const [attachmentFormFields, setAttachmentFormFields] = useState<any>(null);

  const updateFormFields = async () => {
    try {
      const response = await CFCApiHydra.get("/attachment_types?pagination=false");
      if (response && response["hydra:member"].length > 0) {
        fields[0].options = response["hydra:member"].map(
          (opt: { name: string; "@id": string }) => ({
            label: opt.name,
            value: opt["@id"],
          })
        );
      }

      setAttachmentFormFields(fields);

      if (!response) {
        throw new Error("Error while getting attachments type");
      }
    } catch (error) {
      triggerError(error);
    }
  };

  const handleDelete = async (id: string) => {
    triggerLoading(true);
    const response = await CFCApi.delete(
      `/attachments/${extractIdFromStrings(id)}`
    );
    triggerLoading(false);
    if (response.codeStatus === 204) {
      return true;
    }
    triggerError("error while deleting file");
    return false;
  };

  const handleAttachemntClick = (path: string) => {
    const host = process.env.REACT_APP_HOST;
    const folderName = process.env.REACT_APP_ATTACHMENTS_FOLDER;
    if (!host || !folderName || !path) {
      triggerError("Error");
    }
    const url = `${host}/${folderName}/${path}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    (async () => {
      await updateFormFields();
    })();
  }, []);

  return {
    attachmentFormFields,
    setAttachmentFormFields,
    handleDelete,
    handleAttachemntClick,
  };
};

export default useAttachemts;
