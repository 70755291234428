import { useState } from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import { Link } from "react-router-dom";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import Icon from "../components/Icon";
import moment from "moment";

export default function AffectationDossier() {
  const [folders, setFolders] = useState([]);
  const [searchKey, setSearchKey] = useState<null | string>(null);

  const handleSearch = (value: string | null) => {
    if (!value) {
      return;
    }
    setSearchKey(value);
  };

  return (
    <PageWrapper>
      <div className="affectation-container">
        <h1>Modification d’affectation de contact/dossier</h1>

        <div className="acces-commerciaux-search">
          <Search
            handleSearch={handleSearch}
            placeholder="Rechercher le contact/dossier à modifier"
          />
        </div>

        {folders && folders.length > 0 && (
          <div className="folders-listing">
            {folders.map((folder: any, index: number) => (
              <div className="folders-listing-item" key={index}>
                <Link
                  to="/affectation-contact-dossier/attribuer"
                  state={{ folderData: folder }}
                  className="folders-listing-item-inner"
                >
                  <div className="folders-listing-item-content">
                    <p>
                      <span>{folder["@type"]} : Crée le </span>
                      {moment(folder.createdAt).format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <span>Par </span>
                      <b>
                        {folder.firstName} {folder.lastName}
                      </b>
                    </p>
                  </div>
                  <span className="">
                    <Icon icon="note-pencil" size={15} />
                  </span>
                </Link>
              </div>
            ))}
          </div>
        )}

        {folders && folders.length === 0 && searchKey && (
          <div className="text-center">Aucun résultat trouvé.</div>
        )}

        {searchKey && (
          <Pagination
            apiData={`/users/my-contacts?name=${searchKey}&all=true`}
            getCurrentItems={(current) => {
              if (!areObjectsEqual(current, folders)) {
                setFolders(current);
              }
            }}
          />
        )}
      </div>
    </PageWrapper>
  );
}
