import React, { useState, useRef, useEffect } from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactElement;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  let timeout: NodeJS.Timeout;

  const showTooltip = () => {
    clearTimeout(timeout);
    setIsVisible(true);
  };

  const hideTooltip = () => {
    timeout = setTimeout(() => {
      setIsVisible(false);
    }, 200);
  };

  useEffect(() => {
    if (isVisible && tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      tooltipRef.current.classList.remove('tooltip-left', 'tooltip-right');

      if (tooltipRect.left < bodyRect.left) {
        tooltipRef.current.classList.add('tooltip-left');
      } else if (tooltipRect.right > viewportWidth) {
        tooltipRef.current.classList.add('tooltip-right');
      }
    }
  }, [isVisible]);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && (
        <div ref={tooltipRef} className="tooltip">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
