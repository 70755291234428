import Icon from "./Icon";

interface ButtonProps {
  className?: string;
  iconSize?: number;
  text?: string;
  icon?: string;
  to?: any;
  url?: string;
  block?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  onPress?: any;
  submit?: boolean;
  disabled?: boolean;
}

const Button = ({
  className,
  text,
  icon,
  iconSize,
  to,
  block,
  size,
  onPress,
  submit,
  disabled,
}: ButtonProps) => {
  const buttonClasses = [
    "btn",
    block && "btn-block",
    size && `btn-${size}`,
    icon && "btn-icon",
  ];

  if (className) {
    buttonClasses.push(className);
  }

  return (
    <button
      type={submit ? "submit" : "button"}
      className={buttonClasses.join(" ")}
      disabled={disabled}
      onClick={() => {
        if (onPress) {
          onPress();
        } else if (to) {
          to();
        }
      }}
    >
      {icon && (
        <div className="icon-wrapper">
          <Icon icon={icon} size={iconSize || 20} />
        </div>
      )}
      {text && (
        <div className="text-wrapper">
          <span>{text}</span>
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  onPress: null,
};

export default Button;
