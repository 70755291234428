import React from 'react';
import PageWrapper from '../components/Layout/PageWrapper';
import { Grid } from '@mui/material';
import Button from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import CFCApiHydra from '../apis/CFCApiHydra';
import { useDispatch } from 'react-redux';
import { setModel, startLoading, stopLoading } from '../state/slices/appSlice';

export default function ExtractStatistics() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const extractItem = location.state.item;

  const handleExport = async (param: any) => {
    try {
      dispatch(startLoading());

      const response = await CFCApiHydra.downloadFile(
        `/statistics/iframes?statToExport=${param}`,
        'GET'
      );

      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export_${param}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        dispatch(
          setModel({
            type: 'error',
            content: 'An unexpected error occurred while extracting',
            onClose: () => dispatch(setModel(null)),
          })
        );
      }
    } catch (error) {
      console.error('Error while exporting file:', error);
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <PageWrapper>
      <div className="stats-results">
        <div className="stats-results-header">
          <h1>Extraction des statistiques</h1>
        </div>
      </div>

      <div className="stats">
        <Grid columnSpacing={2} container>
          <Grid item xs={12} md={12}>
            <div className="stats-detail">
              <div className="stats-item">
                <h2 className="text-center">{extractItem.title}</h2>
                <div className="stats-iframe-wrapper">
                  <iframe
                    title={extractItem.title}
                    srcDoc={extractItem.content}
                  />
                </div>
                <div className="stats-extract">
                  <Button text="Retour" onPress={() => navigate(-1)} />
                  <Button
                    text="Valider"
                    onPress={() => handleExport(extractItem.exportParam)}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </PageWrapper>
  );
}
