import { Field } from "../../components/Form";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { frFR } from "@mui/x-date-pickers/locales";
import "dayjs/locale/fr";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";

interface DateFieldProps {
  field: Field;
  formik: any;
  options?: any;
}

const DateField: React.FC<DateFieldProps> = ({ field, formik, options }) => {
  const fieldValue: any = formik.values[field.name] || field.defaultValue || "";

  return (
    <div
      key={field.name}
      className={`form-group${field.isReadOnly ? " readonly" : ""}`}
    >
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>
      <div className="input-wrapper input-wrapper-with-icon">
        <span className="icon">
          <Icon icon="calendar" size={20} />
        </span>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={
            frFR.components.MuiLocalizationProvider.defaultProps.localeText
          }
          adapterLocale="fr"
        >
          <MobileDatePicker
            className="form-control"
            defaultValue={dayjs(fieldValue)}
            format="DD/MM/YYYY"
            disablePast={field.disablePast}
            disableFuture={field.disableFuture}
            onChange={(value) =>
              formik.setFieldValue(
                field.name,
                value?.set("hour", 0).set("minute", 0).set("second", 0).format()
              )
            }
          />
        </LocalizationProvider>
        {field.isEditable ? (
          <span className="editable-icon">
            <Icon icon="note-pencil" size={15} />
          </span>
        ) : null}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] && (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      )}
    </div>
  );
};

export default DateField;
