import { useState } from "react";
import { Field } from "../../components/Form";
import Tooltip from "../Tooltip";

interface RadioFieldProps {
  field: Field;
  formik: any;
}

const RadioTwoOptionsField: React.FC<RadioFieldProps> = ({ field, formik }) => {
  const [currentValue, setCurrentValue] = useState(field.defaultValue);
  const handleChange = (e: any, value: string) => {
    const eValue = value || e.target.value;
    let bValue;
    if (eValue === "Oui") {
      bValue = true;
    } else if (eValue === "No") {
      bValue = false;
    } else {
      bValue = null;
    }
    setCurrentValue(bValue);
    formik.setFieldValue(field.name, bValue);
  };

  let toggleClass = "";
  if (currentValue) {
    toggleClass = "active";
  } else if (currentValue === false) {
    toggleClass = "inactive";
  }
  return (
    <div
      key={field.name}
      className={`form-group${field.isHidden ? " form-group-is-hidden" : ""}`}
    >
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
      </div>

      <div className="input-wrapper">
        <div className="form-control-radio-two">
            <label htmlFor="oui">Oui</label>
          <div
            onClick={() => handleChange(null, currentValue ? "No" : "Oui")}
            className={`toggler ${toggleClass}`}
          ></div>
          <label htmlFor="no">No</label>
          <input
            {...formik.getFieldProps(field.name)}
            type="radio"
            id="oui"
            name={field.name}
            checked={field.defaultValue === true ? true : null}
            value="Oui"
            onChange={handleChange}
          />
          <input
            {...formik.getFieldProps(field.name)}
            type="radio"
            id="no"
            name={field.name}
            checked={field.defaultValue === false ? false : null}
            value="No"
            onChange={handleChange}
          />
        </div>
      </div>

      {formik.touched[field.name] && formik.errors[field.name] && (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      )}
    </div>
  );
};

export default RadioTwoOptionsField;
