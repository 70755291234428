import { useState } from "react";
import Icon from "../components/Icon";
import Button from "./Button";

export default function Search({
  handleSearch,
  placeholder,
}: {
  handleSearch: (v: string | null) => void;
  placeholder?: string;
}) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<null | string>(null);

  return (
    <div className={`search-wrapper${isActive ? " active" : ""}`}>
      <div className="bg"></div>
      <form
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSearch(searchKey);
          setIsActive(false);
        }}
      >
        <div className="form-group form-group-search">
          <div className="input-wrapper input-wrapper-with-icon">
            <span className="icon">
              <Icon icon="Search" size={20} />
            </span>
            <input
              onFocus={() => setIsActive(true)}
              className="form-control"
              type="text"
              placeholder={placeholder || "Rechercher par nom"}
              onKeyUp={(e: any) => setSearchKey(e.target.value)}
            />
          </div>
          {isActive && (
            <div className="action">
              <Button
                text="search"
                submit
                onPress={() => {
                  handleSearch(searchKey);
                  setIsActive(false);
                }}
              />
              <button className="btn-cancel" onClick={() => setIsActive(false)}>
                Annuler
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
