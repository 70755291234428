import React, { useState } from 'react';
import PageWrapper from '../components/Layout/PageWrapper';
import { useLocation } from 'react-router-dom';
import Pagination from '../components/Pagination';

import areObjectsEqual from '../helpers/checkObjectsEqual';
import List from '../components/List';

export default function ResultsAPA() {
  const location = useLocation();
  const { state } = location;
  const apasEndpoint = state || {};

  const [apasData, setApasData] = useState<any[]>([]);

  return (
    <PageWrapper>
      <div className="steps-filter-results">
        <List
          title="APA Commerciaux"
          items={apasData}
          navigateTo="/apa/edit-fiche-apa"
          listStyle="bordered"
        />
        <Pagination
          apiData={apasEndpoint}
          getCurrentItems={(current) => {
            const formedData = current.map((item: any) => ({
              name: `${item.firstName} ${item.lastName}`,
              id: item['@id'],
            }));
            if (!areObjectsEqual(formedData, apasData)) {
              setApasData(formedData);
            }
          }}
        />
      </div>
    </PageWrapper>
  );
}
