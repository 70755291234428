import React, { useState, useEffect, useRef } from "react";
import CFCApiHydra from "../../apis/CFCApiHydra";
import { useDispatch } from "react-redux";
import { setModel } from "../../state/slices/appSlice";
import Icon from "../Icon";
import { Link } from "react-router-dom";

interface AutocompleteProps {
  apiUrl: string;
  navigateTo?: string;
  onSuggestionClick?: (suggestion: any) => void;
  formatSuggestion?: (suggestion: any) => {
    display: string;
    id: string;
    type: string;
  };
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  apiUrl,
  navigateTo="#",
  onSuggestionClick,
  formatSuggestion = (suggestion) => ({
    display: `${suggestion["firstName"]} ${suggestion["lastName"]}`,
    id: suggestion["@id"],
    type: suggestion["type"],
  }),
}) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false); // Track if suggestions list is empty
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);

  const fetchSuggestions = async () => {
    if (query.length >= 3) {
      try {
        setIsLoading(true);
        setSuggestions([]);
        const response = await CFCApiHydra.get(
          `${apiUrl}?name=${query}&pagination=false&withRep=true`
        );
        setIsEmpty(response["hydra:member"].length === 0);
        setSuggestions(response["hydra:member"]);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        setIsEmpty(false);
        setModel({
          type: "error",
          content: err.message,
          onClose: () => dispatch(setModel(null)),
        });
      }
    } else {
      setIsEmpty(false);
      setIsLoading(false);
      setSuggestions([]);
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  };

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      fetchSuggestions();
    }, 300);

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [query, apiUrl]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      autocompleteRef.current &&
      !autocompleteRef.current.contains(event.target as Node)
    ) {
      clearSuggestions();
    }
  };

  const clearSuggestions = () => {
    setQuery("");
    setSuggestions([]);
    setIsEmpty(false);
    setIsLoading(false);
  };

  return (
    <div className="autocomplete" ref={autocompleteRef}>
      <div className="form-group form-group-search">
        <div className="input-wrapper input-wrapper-with-icon">
          <span className="icon">
            <Icon icon="Search" size={20} />
          </span>
          <input
            className="form-control"
            type="text"
            placeholder="Nom, prénom, ..."
            value={query}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="autocomplete-results">
        {isLoading && (
          <div className="autocomplete-message autocomplete-loading">
            <span>Chargement ...</span>
          </div>
        )}

        {isEmpty && (
          <div className="autocomplete-message autocomplete-empty">
            Aucune suggestion trouvée.
          </div>
        )}

        {suggestions.length > 0 && (
          <ul>
            {suggestions.map((suggestion, index) => (
              <li key={index}>
                {onSuggestionClick ? (
                  <button
                    onClick={() => {
                      onSuggestionClick(formatSuggestion(suggestion));
                      clearSuggestions();
                    }}
                    className="suggestion-button"
                  >
                    <span>{formatSuggestion(suggestion).display}</span>
                    <Icon icon="eye" size={16} />
                  </button>
                ) : (
                  <Link
                    to={navigateTo}
                    state={{
                      contactData: { id: formatSuggestion(suggestion).id },
                    }}
                  >
                    <span>{formatSuggestion(suggestion).display}</span>
                    <Icon icon="eye" size={16} />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Autocomplete;
