import { FC } from "react";

interface StatValuePart {
  label: string;
  value?: string | number;
  dot?: "full" | "border" | string;
  dotColorClassName?: string;
}

interface StatData {
  label: string;
  value: StatValuePart[] | number;
  dot?: "full" | "border" | string; // Dot property for the top-level
  dotColorClassName?: string;
}

interface InfoBoxProps {
  stat: StatData;
}

const InfoBox: FC<InfoBoxProps> = ({ stat }) => {
  const renderDot = (
    dot?: StatValuePart["dot"],
    dotColorClassName?: string
  ) => {
    const classNames = ["dot"];

    if (dot === "full") {
      classNames.push("dot-full");
    } else if (dot === "border") {
      classNames.push("dot-border");
    }

    if (dotColorClassName) {
      classNames.push(dotColorClassName);
    }

    return <span className={classNames.join(" ")} />;
  };

  const renderValue = (
    value: StatData["value"],
    topLevelDot?: StatData["dot"]
  ) => {
    if (typeof value === "number" || typeof value === "string") {
      return value;
    }

    return (
      <ul className="sub-stats">
        {value.map((part, index) => (
          <li key={index} className="sub-stat">
            {part.dot && renderDot(part.dot, part.dotColorClassName)}
            {part.value !== undefined && (
              <span className="sub-stat-value">{part.value}</span>
            )}
            <span className="sub-stat-label">{part.label}</span>
          </li>
        ))}
        {topLevelDot && renderDot(topLevelDot, stat.dotColorClassName)}
      </ul>
    );
  };

  return (
    <div className="info-box">
      <div className="top-level-stats">
        {stat.label && (
          <div className="stat-label-wrapper">
            {stat.dot && renderDot(stat.dot)}
            <h3 className="stat-label">{stat.label}</h3>
          </div>
        )}
        <span className="stat-value">{renderValue(stat.value)}</span>
      </div>
    </div>
  );
};

export default InfoBox;
